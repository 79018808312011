import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'react-modal'
import RegionDrawing from './RegionDrawing'

class RegionDrawingZoomModal extends React.Component {
  static propTypes = {
    showEnlargedImage: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    regionDrawingProps: PropTypes.object,
    mouseUpKey: PropTypes.number.isRequired,
    responsesAvailable: PropTypes.bool,
    kind: PropTypes.string,
    clearPoints: PropTypes.func
  }
  constructor (props) {
    super(props)
    this.state = {
      zoomIndex: 4
    }
    // In percentage of original image size going from 10% to 400%
    this.zoomPerc = [0.1, 0.25, 0.5, 0.75, 1, 1.5, 2, 3, 4]
  }
  render () {
    const { showEnlargedImage, closeModal, mouseUpKey, regionDrawingProps, type, kind,
      responsesAvailable, clearPoints } = this.props
    const customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(37, 37, 37, 0.6)',
        zIndex: 1000000
      },
      content: {
        width: 'calc(100% - 20px)',
        position: 'relative',
        zIndex: 1000001
      }
    }
    let zoomOutDisabled = false
    let zoomInDisabled = false
    if (this.state.zoomIndex === 0) {
      zoomOutDisabled = true
    } else if (this.state.zoomIndex === this.zoomPerc.length - 1) {
      zoomInDisabled = true
    }
    const minSizeClass = type === 'instructorDrawing' ? ' minWindowSize' : ''
    let instructorDescription = 'Click to outline the region of the image that represents the correct answer.'
    if (kind === 'HOTSPOT') {
      instructorDescription = 'Click at least one spot on the image to represent the correct response'
      instructorDescription += ' and adjust the tolerance accordingly.'
    } else if (kind === 'DIRECTION') {
      instructorDescription = 'Click to place one point where the direction vector starts and click'
      instructorDescription += ' to the place the point where the correct direction vector ends.'
    }
    return (
      <Modal
        isOpen={showEnlargedImage}
        style={customStyles}
        className='pe-proto modal modal-large'
        role='dialog'
        contentLabel='Enlarged image view for region drawing'
        onRequestClose={closeModal}
      >
        <div id='regionZoomModal' className='pe-template__static-large zoomModalContainingDiv'>
          <div className={'enlargeImageDiv' + minSizeClass}>
            <div id='modal-title' className='questionFormModalTitle'>
              <div className='zoomDescriptionDiv'>
                {type === 'instructorDrawing' &&
                  <span>{instructorDescription}</span>
                }
              </div>
              <button type='button' className='pe-icon--btn closeButton' aria-label='Close dialog'
                onClick={closeModal}>
                <svg version='1.1' xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink' role='img'
                  focusable='false'
                  aria-labelledby='close-24-icon'
                  className='pe-icon--close-24'>
                  <title id='close-24-icon'>Close dialog</title>
                  <use xlinkHref='#close-24' />
                </svg>
              </button>
            </div>
            <div className='zoomTools'>
              <button type='button' className='pe-icon--btn' aria-label='Zoom out' disabled={zoomOutDisabled}
                onClick={() => this.setState({ zoomIndex: this.state.zoomIndex - 1 })}>
                <svg version='1.1' xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink' role='img'
                  focusable='false'
                  aria-labelledby='zoom-out-18-icon'
                  className='pe-icon--zoom-out-18'>
                  <title id='zoom-out-18-icon'>Zoom out</title>
                  <use xlinkHref='#zoom-out-18' />
                </svg>
              </button>
              <button type='button' className='pe-icon--btn zoomIn' aria-label='Zoom in' disabled={zoomInDisabled}
                onClick={() => this.setState({ zoomIndex: this.state.zoomIndex + 1 })}>
                <svg version='1.1' xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink' role='img'
                  focusable='false'
                  aria-labelledby='zoom-in-18-icon'
                  className='pe-icon--zoom-in-18'>
                  <title id='zoom-in-18-icon'>Zoom in</title>
                  <use xlinkHref='#zoom-in-18' />
                </svg>
              </button>
            </div>
            {type === 'instructorDrawing'
                ? null
              : (type === 'studentAnswer'
                  ? <RegionDrawing
                      mouseUpKey={mouseUpKey}
                      zoomPerc={this.zoomPerc[this.state.zoomIndex]}
                      studentResponses={regionDrawingProps.studentResponses}
                      hideFullAnswer={true}
                      numberRegions={regionDrawingProps.numberRegions}
                      imagePreviewUrl={regionDrawingProps.imagePreviewUrl}
                      redrawKey={regionDrawingProps.redrawKey}
                      alternativeText={regionDrawingProps.alternateText}
                      questionType={regionDrawingProps.questionType}
                      type={regionDrawingProps.type}
                      uniqueId={regionDrawingProps.uniqueId}
                      moveChangeRegionPoint={regionDrawingProps.moveChangeRegionPoint}
                      deletePoint={regionDrawingProps.deletePoint}
                    />
                  : <RegionDrawing
                    studentResponses={regionDrawingProps.studentResponses}
                    correctRegions={regionDrawingProps.correctRegions}
                    imagePreviewUrl={regionDrawingProps.imagePreviewUrl}
                    type={regionDrawingProps.type}
                    uniqueId={regionDrawingProps.uniqueId}
                    zoomPerc={this.zoomPerc[this.state.zoomIndex]}
                    provideAnswer={regionDrawingProps.provideAnswer}
                    moveChangeRegionPoint={regionDrawingProps.moveChangeRegionPoint}
                    deletePoint={regionDrawingProps.deletePoint}
                    mouseUpKey={regionDrawingProps.mouseUpKey}
                    numberRegions={regionDrawingProps.numberRegions}
                    questionType={regionDrawingProps.questionType}
                    tolerance={regionDrawingProps.tolerance}
                    redrawKey={regionDrawingProps.redrawKey}
                  />
              )
            }
            {responsesAvailable > 0 &&
              <button
                id='deletePoints'
                className='enlargeImage clearImageButton'
                aria-label='Clear'
                onClick={clearPoints}
              >
                Clear
              </button>
            }
          </div>
        </div>
      </Modal>
    )
  }
}

export default RegionDrawingZoomModal
