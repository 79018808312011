import React, { FunctionComponent } from "react";
import styles from "./container.module.scss";

enum layouts {
  row = "row",
  column = "column",
}

enum aligns {
  start_start = "start_start",
  start_center = "start_center",
  start_end = "start_end",
  end_start = "end_start",
  end_end = "end_end",
  end_center = "end_center",
  center_start = "center_start",
  center_end = "center_end",
  center_center = "center_center",
  space_between_start = "space_between_start",
  space_between_center = "space_between_center",
  space_between_end = "space_between_end",
  space_around_start = "space_around_start",
  space_around_end = "space_around_end",
  space_around_center = "space_around_center",
}

interface Props {
  layout?: layouts;
  align?: aligns;
  columnstart?: number;
  columnend?: number;
  rowstart?: number;
  rowend?:number;
}

const Container: FunctionComponent<Props> = (props) => {
  return (
    <div
      style={{
        gridColumnStart: props.columnstart,
        gridColumnEnd: props.columnend,
        gridRowStart: props.rowstart,
        gridRowEnd:props.rowend
      }}
      {...props}
      className={`${styles[`layout__${props.layout}`]} ${
        styles[`layout__align_${props.align}`]
      } ${props.className}`}
    >
      {props.children}
    </div>
  );
};

Container.defaultProps = {
  layout: layouts.column,
  align: aligns.start_start,
};

export default Container;
