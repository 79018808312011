import React, { FunctionComponent, useState, useRef } from "react";
import style from "./tooltip.module.scss";
import Popper from "@popperjs/core";
import { usePopper } from "react-popper";
import { generateId } from "./../_util/common.js";

type Props = {
  tooltipId: string;
  message: string;
  placement: Popper.Placement;
  children: React.ReactNode;
  ariaHidden: boolean;
  tooltipCustomStyle: object; //Add custom styles for the Tooltip
};

const ToolTip: FunctionComponent<Props> = ({
  tooltipId,
  message,
  placement,
  children,
  ariaHidden,
  tooltipCustomStyle
}) => {
  const [arrowElement, setArrowElement] = useState(null);
  const [visibile, setVisibile] = useState(false);
  const referenceRef = useRef(null);
  const popperRef = useRef(null);

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: placement,
      modifiers: [
        { name: "offset", options: { offset: [0, 14] } },
        { name: "arrow", options: { element: arrowElement } },
        { name: "preventOverflow", options: { rootBoundary: "document" } },
      ],
    }
  );
  const tooltipStyle = {...styles.popper, ...tooltipCustomStyle}
  
  const showToolTip = () => {
    setVisibile(true);
  };

  const hideToolTip = () => {
    setVisibile(false);
  };

  return (
    <div
      onMouseOver={showToolTip}
      onMouseOut={hideToolTip}
      onFocus={showToolTip}
      onBlur={hideToolTip}
    >
      <div ref={referenceRef}>{children}</div>
      {visibile ? (
        <div
          className={style.tooltip}
          id={tooltipId}
          ref={popperRef}
          style={tooltipStyle}
          {...attributes.popper}
          aria-label={message}
          aria-hidden={ariaHidden}
          role="tooltip"
        >
          {message}
          <div
            className={style.arrow}
            style={styles.arrow}
            ref={setArrowElement}
          ></div>
        </div>
      ) : null}
    </div>
  );
};

ToolTip.defaultProps = {
  tooltipId: generateId(),
  message: "ToolTip",
  placement: "left",
  ariaHidden: false,
  tooltipCustomStyle: {fontFamily: 'Open Sans semibold'}
};

export default ToolTip;
