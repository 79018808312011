import React, { FunctionComponent } from "react";
import styles from "./card.module.scss";

enum fontSizes {
  jumbo = "jumbo",
  xLarge = "xLarge",
  large = "large",
  medium = "medium",
  multiMedium = "multiMedium",
  small = "small",
  multiSmall = "multiSmall",
  xSmall = "xSmall",
}

enum tags {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  p = "p",
}

interface Props {
  size: fontSizes;
  truncate?: number;
  color?: string;
  tag?: tags;
  width?: string;
}

const CardText: FunctionComponent<Props> = (props) => {
  function generateClassName() {
    switch (props.size) {
      case fontSizes.jumbo:
        return `${styles.card_text} ${styles.card_text_jumbo}`;

      case fontSizes.xLarge:
        return `${styles.card_text} ${styles.card_text_xLarge}`;

      case fontSizes.large:
        return `${styles.card_text} ${styles.card_text_large}`;

      case fontSizes.medium:
        return `${styles.card_text} ${styles.card_text_medium}`;

      case fontSizes.multiMedium:
        return `${styles.card_text} ${styles.card_text_multiMedium}`;

      case fontSizes.small:
        return `${styles.card_text} ${styles.card_text_small}`;

      case fontSizes.multiSmall:
        return `${styles.card_text} ${styles.card_text_multiSmall}`;

      case fontSizes.xSmall:
        return `${styles.card_text} ${styles.card_text_xSmall}`;

      default:
        return `${styles.card_text} ${styles.card_text_multiSmall}`;
    }
  }

  function generateStyles() {
    let response = {};
    if (props.truncate) {
      response = {
        display: "-webkit-box",
        WebkitLineClamp: `${props.truncate}`,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-all",
      };
    }
    if (props.color) {
      response["color"] = props.color;
    }
    return response;
  }

  if (props.truncate) {
    return (
      <div
        className={styles.card_text_container}
        style={props.width ? { width: props.width } : null}
      >
        {React.createElement(
          `${props.tag}`,
          { style: generateStyles(), className: generateClassName() },
          props.children
        )}
        <span className={`${generateClassName()} ${styles.card_text_tooltip}`}>
          {props.children}
        </span>
      </div>
    );
  } else {
    return React.createElement(
      `${props.tag}`,
      { style: generateStyles(), className: generateClassName() },
      props.children
    );
  }
};

CardText.defaultProps = {
  size: fontSizes.multiSmall,
  tag: tags.p,
};

export default CardText;
