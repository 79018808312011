/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from "react";
import styles from "./card.module.scss";

enum buttonTypes {
  stack = "stack",
  modular = "modular",
}

enum columnVariations {
  three = 3,
  four = 4,
  ten = 10,
  twelve = 12,
}

interface Props {
  action?: ()=> void;
  buttonType: buttonTypes;
  columns: columnVariations;
  id: string;
}

class ButtonContainer extends Component<
  Props & React.HTMLAttributes<HTMLDivElement>,
  unknown
> {
  computeClassName = () => {
    switch (this.props.buttonType) {
      case buttonTypes.modular:
        switch (this.props.columns) {
          case columnVariations.three:
            return `${styles.card} ${styles.card_modular} ${styles.card_span_3} ${this.props.className}`;

          case columnVariations.four:
            return `${styles.card} ${styles.card_modular} ${styles.card_span_4} ${this.props.className}`;

          default:
            return `${styles.card} ${styles.card_modular} ${styles.card_span_3} ${this.props.className}`;
        }

      case buttonTypes.stack:
        switch (this.props.columns) {
          case columnVariations.ten:
            return `${styles.card} ${styles.card_stack} ${styles.card_span_10} ${this.props.className}`;

          case columnVariations.twelve:
            return `${styles.card} ${styles.card_stack} ${styles.card_span_12} ${this.props.className}`;

          default:
            return `${styles.card} ${styles.card_stack} ${styles.card_span_10} ${this.props.className}`;
        }
    }
  };

  render() {
    return (
      <div
        id={this.props.id}
        tabIndex={0}
        className={`${this.computeClassName() + " " + styles.button_text}`}
        onClick={() => this.props.action()}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ButtonContainer;
