/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2018 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import React from "react";
import { func, number, string } from "prop-types";
import PaginationButton from "./common/PaginationButton";
import "./Pagination.scss";
import PaginationAnchor from "./PaginationAnchor";
const INC = 1;
const DEC = -1;
const NEW = "newPage";
const PREVIOUS = "previousPage";
const NEXT = "nextPage";
const MAXIMUM = "maximum";

class MaximumPagination extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pageNumbers: [],
      pageChanged: true,
      currentPage: this.props.currentPageNum,
      manualClick: false,
    };
    this.paginationItems = [];
  }

  updateRefArray = () => {
    const numOfItems = this.props.totalPages + 1;
    let i = 0;
    while (i < numOfItems) {
      this.paginationItems[i] = React.createRef();
      i++;
    }
  };

  componentDidMount() {
    var pageNumArr = this.handleInitialPageNumArray();
    this.setState({ pageNumbers: pageNumArr }, () => {
      this.updateRefArray();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPageNum !== this.props.currentPageNum) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        (prevState) => ({
          currentPage: this.props.currentPageNum,
          pageChanged: true,
        }),
        () => {
          var pageNumArr = this.handleInitialPageNumArray();
          this.setState({ pageNumbers: pageNumArr }, () => {
            this.updateRefArray();
          });
        }
      );
    }

    if (
      this.paginationItems &&
      this.paginationItems[this.state.currentPage] &&
      this.paginationItems[this.state.currentPage].current &&
      this.state.manualClick
    ) {
      this.paginationItems[this.state.currentPage].current.focus();
    }

    if (prevProps.totalPages !== this.props.totalPages) {
      var pageNumArr = this.handleInitialPageNumArray();
      this.setState(
        { pageNumbers: pageNumArr, pageChanged: true, currentPage: 1 },
        () => {
          this.updateRefArray();
        }
      );
    }
  }

  handleInitialPageNumArray = () => {
    let { initialPageCount, totalPages, currentPageNum } = this.props;
    var pageNumArr = [];
    if (initialPageCount <= totalPages) {
      if (currentPageNum <= initialPageCount) {
        for (var i = 1; i <= initialPageCount; i++) {
          pageNumArr.push(i);
        }
      } else if (currentPageNum > totalPages - initialPageCount) {
        for (i = 1; i <= initialPageCount; i++) {
          pageNumArr.push(totalPages - initialPageCount + i);
        }
      } else {
        for (i = 0; i <= initialPageCount; i++) {
          pageNumArr.push(currentPageNum + i);
        }
      }
    } else {
      console.log("Invalid initial page count");
    }
    return pageNumArr;
  };

  logicToHandlePagination = (type) => {
    let { currentPage } = this.state;
    let { initialPageCount, totalPages } = this.props;
    if (
      type === INC &&
      currentPage > initialPageCount &&
      (currentPage <= totalPages - initialPageCount + 1 ||
        totalPages < initialPageCount * 2)
    ) {
      this.incrementingArray();
    }
    if (
      type === DEC &&
      currentPage >= initialPageCount &&
      (currentPage <= totalPages - initialPageCount ||
        totalPages < initialPageCount * 2)
    ) {
      this.decrementingArray();
    }
    if (
      type === NEW &&
      (currentPage <= initialPageCount ||
        currentPage > totalPages - initialPageCount)
    ) {
      this.newPageSelectedArray();
    }
  };

  incrementingArray = () => {
    let { currentPage, pageNumbers } = this.state;
    let { initialPageCount, totalPages } = this.props;
    let newArray = [];

    if (
      (currentPage === totalPages - initialPageCount + 1 &&
        totalPages >= 2 * initialPageCount) ||
      (currentPage > initialPageCount && totalPages < initialPageCount * 2)
    ) {
      for (var i = 0; i < initialPageCount; i++) {
        newArray[initialPageCount - 1 - i] = totalPages - i;
      }
    } else {
      pageNumbers.map((value, index) => (newArray[index] = value + 1));
    }
    this.setState({ pageNumbers: newArray }, () => {/**/});
  };

  decrementingArray = () => {
    let { currentPage, pageNumbers } = this.state;
    let { initialPageCount, totalPages } = this.props;
    let newArray = [];

    if (currentPage === totalPages - initialPageCount) {
      pageNumbers.map(
        (value, index) => (newArray[index] = value - initialPageCount)
      );
    } else if (
      (currentPage <= initialPageCount && totalPages < initialPageCount * 2) ||
      currentPage === initialPageCount
    ) {
      pageNumbers.map((value, index) => (newArray[index] = index + 1));
    } else {
      pageNumbers.map((value, index) => (newArray[index] = value - 1));
    }
    this.setState({ pageNumbers: newArray });
  };

  newPageSelectedArray = () => {
    let { currentPage, pageNumbers } = this.state;
    let { initialPageCount, totalPages } = this.props;
    let newArray = [];

    if (currentPage <= initialPageCount && totalPages < initialPageCount * 2) {
      pageNumbers.map((value, index) => (newArray[index] = index + 1));
    } else if (
      currentPage >= initialPageCount &&
      totalPages < initialPageCount * 2
    ) {
      for (var i = 0; i < initialPageCount; i++) {
        newArray[initialPageCount - 1 - i] = totalPages - i;
      }
    } else if (currentPage <= initialPageCount) {
      pageNumbers.map((value, index) => (newArray[index] = index + 1));
    } else if (currentPage >= totalPages - initialPageCount) {
      pageNumbers.map(
        (value, index) =>
          (newArray[index] = totalPages - initialPageCount + index + 1)
      );
    }
    this.setState({ pageNumbers: newArray });
  };

  nextPage = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
        pageChanged: true,
        manualClick: false,
      }),
      () => {
        this.logicToHandlePagination(INC);
        this.props.handlePage(this.state.currentPage, NEXT);
      }
    );
  };

  previousPage = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage - 1,
        pageChanged: true,
        manualClick: false,
      }),
      () => {
        this.logicToHandlePagination(DEC);
        this.props.handlePage(this.state.currentPage, PREVIOUS);
      }
    );
  };

  newPage = (e) => {
    this.setState(
      (prevState) => ({
        currentPage: e,
        pageChanged: true,
        manualClick: true,
      }),
      () => {
        this.logicToHandlePagination(NEW);
        this.props.handlePage(this.state.currentPage, NEW);
      }
    );
  };

  // this will generate numbered Anchor tags
  numberATags = (currentPage, newPageFunc) => {
    const that = this;
    const anchorElements = this.state.pageNumbers.map(function (e, i) {
      if (e === currentPage) {
        return (
          <PaginationAnchor
            ref={that.paginationItems[e]}
            onClick={newPageFunc}
            current
            pageNumber={e}
            key={"laf" + i}
            type={MAXIMUM}
          />
        );
      } else {
        return (
          <PaginationAnchor
            ref={that.paginationItems[e]}
            onClick={newPageFunc}
            pageNumber={e}
            key={"laf" + i}
            type={MAXIMUM}
          />
        );
      }
    });
    return anchorElements;
  };

  render() {
    let { totalPages, dataPerPage, numberOfRecords } = this.props;
    if (totalPages == null) {
      totalPages = "";
    }
    let disabledBackButton = this.state.currentPage <= 1;
    let disabledNextButton = this.state.currentPage === totalPages;
    let backAriaLabel = this.props.backAriaLabel;
    let nextAriaLabel = this.props.nextAriaLabel;

    if (disabledBackButton) {
      backAriaLabel += ", disabled currently";
    }
    if (disabledNextButton) {
      nextAriaLabel += ", disabled currently";
    }
    if (
      !(numberOfRecords <= dataPerPage) &&
      !(this.state.currentPage === 0 || this.state.currentPage > totalPages)
    ) {
      return (
        <div
          className="ls-pagination"
          role="navigation"
          aria-label="pagination"
        >
          <PaginationButton
            ariaLabel={backAriaLabel}
            isDisabled={this.state.currentPage === 1}
            onClick={this.previousPage}
            iconFileName="chevron-next-sm-18"
            iconName="back-page"
            iconTitle="Prior Page"
            buttonId="prevPage"
            svgClass="back-svg"
          />

          <span
            aria-live="polite"
            role="alert"
            className="sr-only"
          >{"page " + this.state.currentPage}</span>
          {this.state.pageChanged &&
            this.numberATags(this.state.currentPage, this.newPage)}
          <PaginationButton
            ariaLabel={nextAriaLabel}
            isDisabled={this.state.currentPage === totalPages}
            onClick={this.nextPage}
            iconFileName="chevron-next-sm-18"
            iconName="next-page"
            iconTitle="Next Page"
            buttonId="nexPage"
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

MaximumPagination.propTypes = {
  currentPageNum: number.isRequired,
  totalPages: number.isRequired,
  dataPerPage: number,
  numberOfRecords: number,
  handlePage: func,
  pageLabel: string,
  initialPageCount: number.isRequired,
  backAriaLabel: string,
  nextAriaLabel: string
};
MaximumPagination.defaultProps = {
  handlePage: () => {/**/},
};

export default MaximumPagination;
