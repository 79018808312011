import React, { Component } from 'react';
import PropTypes from 'prop-types';

import QuestionPrompt from "./QuestionPrompt";
import MultipleChoiceAnswerSection from "./MultipleChoiceAnswerSection";
import ShortAnswerSection from "./ShortAnswerSection";
import RegionAnswerSection from "./RegionAnswerSection";

import styles from "./_answersubmission.module.scss";

class AnswerSubmission extends Component {
  render () {
    const { question } = this.props
    let answerSection
    switch (question.kind) {
      case 'MULTIPLE_CHOICE':
        answerSection = <MultipleChoiceAnswerSection
          questionId={question.id}
          options={question.options}
          multipleSelect={question.multipleSelect}
          initialState={this.props.initialState}
          onChange={this.props.onChange}
          onSubmit={this.props.onSubmit}
        />
        break
      case 'SHORT_ANSWER':
        answerSection = <ShortAnswerSection
          questionId={question.id}
          initialState={this.props.initialState}
          onChange={this.props.onChange}
          onSubmit={this.props.onSubmit}
        />
        break
      // case 'MATCHING': {
      //   answerSection = <MatchingAnswerSection />
      //   break
      // }
      case 'REGION':
      case 'HOTSPOT':
      case 'DIRECTION':
        answerSection = <RegionAnswerSection
          questionId={question.id}
          type={question.kind === 'DIRECTION' ? 'directionResponse' : 'response'}
          initialState={this.props.initialState}
          imageUrl={question.imageUrl}
          alternateText={question.alternateText}
          noOfCorrectRegions={question.noOfCorrectRegions}
          onChange={this.props.onChange}
          onSubmit={this.props.onSubmit}
        />
        break
      // case 'NUMERICAL': {
      //   answerSection = <NumericalAnswerSection />
      //   break
      // }
      default:
        answerSection = <span>Invalid Question Kind</span>
        break
    }

    return (
      <div className={styles.answerSubmission}>
        <QuestionPrompt
          questionId={question.id}
          prompt={question.prompt}
        />
        {answerSection}
      </div>
    );
  }
}

AnswerSubmission.propTypes = {
  question: PropTypes.object.isRequired,
  initialState: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
}

export default AnswerSubmission;
