import React from 'react'
import PropTypes from 'prop-types'

import RegionDrawing from './RegionDrawing'
import RegionDrawingZoomModal from './RegionDrawingZoomModal'
import Button from '../Button'

class RegionAnswerSection extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mouseUpKey: 0, // key sent down to RegionDrawing component to indicate the mouse is up
      redrawKey: 0,
      points: this.props.initialState ? this.props.initialState : [],
      showEnlargedImage: false
    }
  }

  endPointEdit = () => {
    const mouseUpKey = this.state.mouseUpKey + 1
    this.setState({
      mouseUpKey
    })
  }

  clearPoints = () => {
    this.setState({
      points: [],
      redrawKey: this.state.redrawKey + 1
    }, () => {
      if (this.props.onChange) this.props.onChange(this.state.points)
    })
  }

  deletePoint = (index) => {
    let points = this.state.points
    points.splice(index, 1)
    this.setState({
      points: points,
      redrawKey: this.state.redrawKey + 1
    }, () => {
      if (this.props.onChange) this.props.onChange(this.state.points)
    })
  }

  moveChangeRegionPoint = (index, currentPointIndex, x, y) => {
    let points = this.state.points
    points[index] = [x, y]
    this.setState({
      points: points,
      redrawKey: this.state.redrawKey + 1
    }, () => {
      if (this.props.onChange) this.props.onChange(this.state.points)
    })
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.points)
  }

  showEnlargedImage = () => {
    this.setState({ showEnlargedImage: true })
  }

  hideEnlargedImage = () => {
    this.setState({ showEnlargedImage: false })
  }

  render () {
    const { questionId, type, imageUrl, alternateText, noOfCorrectRegions } = this.props
    const { mouseUpKey, redrawKey, points, showEnlargedImage } = this.state

    return (
      <div className='region_question_container' onMouseUp={this.endPointEdit} key={questionId}>
        <RegionDrawingZoomModal
          showEnlargedImage={showEnlargedImage}
          closeModal={this.hideEnlargedImage}
          mouseUpKey={mouseUpKey}
          type='studentAnswer'
          responsesAvailable={false}
          regionDrawingProps={{
            studentResponses: points,
            numberRegions: noOfCorrectRegions,
            imagePreviewUrl: imageUrl,
            redrawKey: redrawKey,
            alternateText: alternateText,
            questionType: 'REGION',
            type: type,
            uniqueId: 'studentResponse',
            moveChangeRegionPoint: this.moveChangeRegionPoint,
            deletePoint: this.deletePoint
          }}
          clearPoints={this.clearPoints}
        />
        <div className='region-field' id={`${questionId}-rg`}>
          <div>
            <RegionDrawing
              studentResponses={points}
              hideFullAnswer={true}
              mouseUpKey={mouseUpKey}
              numberRegions={noOfCorrectRegions}
              imagePreviewUrl={imageUrl}
              redrawKey={redrawKey}
              alternativeText={alternateText}
              questionType='REGION'
              type={type}
              uniqueId='studentResponse'
              moveChangeRegionPoint={this.moveChangeRegionPoint}
              deletePoint={this.deletePoint}
            />
            <div>
              <button
                id='enlargeRegionImage'
                className='enlargeImage'
                aria-label='Enlarge image'
                onClick={this.showEnlargedImage}
              >
                Enlarge image
              </button>
              { points.length > 0 &&
                <button
                  id='clear-region-button'
                  aria-label='Clear'
                  onClick={this.clearPoints}
                >
                  Clear
                </button>
              }
            </div>
          </div>
        </div>
        <Button
          id={`${questionId}-submit-button`}
          buttonSize='large'
          buttonType='default'
          isDisabled={false}
          onClick={this.onSubmit}
        >Submit</Button>
      </div>
    )
  }
}

RegionAnswerSection.propTypes = {
  questionId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  initialState: PropTypes.array,
  imageUrl: PropTypes.string,
  alternateText: PropTypes.string,
  noOfCorrectRegions: PropTypes.number,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
}

export default RegionAnswerSection
