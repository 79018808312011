import React from "react";
import PropType from "prop-types";
import Icon from "./../Icon";

const PaginationAnchor = React.forwardRef(
  ({ onClick, current, pageNumber }, ref) => {
    const handleClick = () => {
      onClick(pageNumber);
    };

    if (current) {
      return (
        <a
          ref={ref}
          tabIndex="0"
          role="button"
          onClick={handleClick}
          onKeyPress={handleClick}
          aria-current="page"
          aria-label={"page " + pageNumber}
        >
          <span>{pageNumber}</span>
        </a>
      );
    } else if (pageNumber === "e") {
      return (
        <span className="ls-ellipsis">
          <a>
            <Icon
              fileName="ellipsis-18"
              name="ellipsis-18"
              title="ellipsis-18"
            />
          </a>
        </span>
      );
    } else {
      return (
        <a
          ref={ref}
          tabIndex="0"
          role="button"
          onClick={handleClick}
          onKeyPress={handleClick}
          aria-label={"page " + pageNumber}
        >
          <span>{pageNumber}</span>
        </a>
      );
    }
  }
);

PaginationAnchor.propTypes = {
  onClick: PropType.func,
  current: PropType.bool,
  pageNumber: PropType.oneOfType([PropType.number, PropType.string]),
};
export default PaginationAnchor;
