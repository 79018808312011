import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CheckBox from "../CheckBox";
import RadioOption from "../RadioOption";

class MultipleChoiceOption extends Component {
  // Below prevents a bug with jumping tables by confirming if this component should re-render
  shouldComponentUpdate (nextProps) {
    return nextProps.option !== this.props.option ||
      nextProps.checked !== this.props.checked ||
      nextProps.id !== this.props.id
  }

  render () {
    const { index, id, option, checked, type, toggleOption } = this.props
    const label = () => {
      return <div>
        <span className='letter'>{String.fromCharCode(65 + index)}.</span>
        <span className='answer-text-ctnr'
          id={`answer-text-${id}`}
          dangerouslySetInnerHTML={{ __html: option }} />
      </div>
    }

    if (type === 'checkbox') {
      return (
        <CheckBox
          id={id}
          value={index}
          checked={checked}
          onCheckboxChange={() => toggleOption(index)}
          size="18"
          optionHeight="42"
        >
          {label()}
        </CheckBox>
      )
    } else {
      return (
        <RadioOption
          id={id}
          value={index}
          selectedValue={checked ? index : -1}
          onChange={() => toggleOption(index)}
        >
          {label()}
        </RadioOption>
      )
    }
  }
}

MultipleChoiceOption.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  toggleOption: PropTypes.func.isRequired
}

export default MultipleChoiceOption
