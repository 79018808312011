// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".toggleButton_toggle_button__FDSrD{display:flex;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:4px;background-color:#eee;padding:2px;margin-bottom:16px}.toggleButton_toggle_button_item__JWxqb{border:none;height:32px;border-radius:4px;width:auto;min-width:142px;text-align:center;color:#505759;font-weight:600;font-size:16px}.toggleButton_toggle_button_item__JWxqb:hover{color:#047a9c;cursor:pointer}.toggleButton_toggle_button_item__JWxqb+.toggleButton_toggle_button_item__JWxqb{margin-left:2px}.toggleButton_toggle_button_item_selected__5yNcw{color:#252525;background-color:#fff}\n", "",{"version":3,"sources":["webpack://./src/components/ToggleButton/toggleButton.module.scss"],"names":[],"mappings":"AACE,mCACE,YAAa,CACb,yBAAkB,CAAlB,sBAAkB,CAAlB,iBAAkB,CAClB,iBAAkB,CAClB,qBAAsB,CACtB,WAAY,CACZ,kBAAmB,CACnB,wCACE,WAAY,CACZ,WAAY,CACZ,iBAAkB,CAClB,UAAW,CACX,eAAgB,CAChB,iBAAkB,CAClB,aAAc,CACd,eAAgB,CAChB,cAAe,CAThB,8CAYG,aAAc,CACd,cAAe,CAblB,gFAiBG,eAAgB,CACjB,iDAGC,aAAc,CACd,qBAAsB","sourcesContent":[".toggle {\n  &_button {\n    display: flex;\n    width: fit-content;\n    border-radius: 4px;\n    background-color: #eee;\n    padding: 2px;\n    margin-bottom: 16px;\n    &_item {\n      border: none;\n      height: 32px;\n      border-radius: 4px;\n      width: auto;\n      min-width: 142px;\n      text-align: center;\n      color: #505759;\n      font-weight: 600;\n      font-size: 16px;\n\n      &:hover {\n        color: #047a9c;\n        cursor: pointer;\n      }\n\n      & + .toggle_button_item {\n        margin-left: 2px;\n      }\n\n      &_selected {\n        color: #252525;\n        background-color: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle_button": "toggleButton_toggle_button__FDSrD",
	"toggle_button_item": "toggleButton_toggle_button_item__JWxqb",
	"toggle_button_item_selected": "toggleButton_toggle_button_item_selected__5yNcw"
};
export default ___CSS_LOADER_EXPORT___;
