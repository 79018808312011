import React, { Component } from 'react';
import PropTypes from 'prop-types'

import MultipleChoiceOption from "./MultipleChoiceOption";
import Button from "../Button";

class MultipleChoiceAnswerSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedIndexes: this.props.initialState ? this.props.initialState : []
    }
  }

  toggleOption = (index) => {
    if (this.props.multipleSelect) {
      const i = this.state.selectedIndexes.indexOf(index)
      if (i > -1) {
        let indexes = this.state.selectedIndexes
        indexes.splice(i, 1)
        this.setState({
          selectedIndexes: indexes
        }, () => this.onChange())
      } else {
        this.setState({
          selectedIndexes: [...this.state.selectedIndexes, index]
        }, () => this.onChange())
      }
    } else {
      this.setState({
        selectedIndexes: [index]
      }, () => this.onChange())
    }
  }

  onChange = () => {
    if (this.props.onChange) this.props.onChange(this.state.selectedIndexes)
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.selectedIndexes)
  }

  render () {
    const { questionId, options, multipleSelect } = this.props
    const { selectedIndexes } = this.state

    const questionOptions = options
      .map((option, i) => {
        return <MultipleChoiceOption
          key={`question-option-${i}`}
          index={i}
          id={`${questionId}-option-${i}`}
          option={option}
          checked={selectedIndexes.indexOf(i) > -1}
          type={multipleSelect ? 'checkbox' : 'radio'}
          toggleOption={this.toggleOption}
        />
      }
    )
    return (
      <div className='answer-section'>
        <div className='answer-options'>
          <fieldset id={`${questionId}-rg`} className='pe-fieldset'>
            <legend className='pe-legend'>Select all that apply</legend>
            {questionOptions}
          </fieldset>
        </div>
        <Button
          id={`${questionId}-submit-button`}
          buttonSize='large'
          buttonType='default'
          isDisabled={false}
          onClick={this.onSubmit}
        >Submit</Button>
      </div>
    )
  }
}

MultipleChoiceAnswerSection.propTypes = {
  questionId: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multipleSelect: PropTypes.bool.isRequired,
  initialState: PropTypes.array,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
}

export default MultipleChoiceAnswerSection
