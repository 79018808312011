import React from "react";
import Icon from '../Icon';
import styles from "./sideDrawer.module.scss";

const SideDrawerHeader = ({titleIcon, title, children }) => {
    return (
        <div className={styles.SideDrawerHeader} >
            <div className={styles.lqTitle} >
                <Icon name={`${titleIcon}-18`} fileName={`${titleIcon}-18`} title={`${titleIcon}-18`} />
                <p className={styles.lqTitleText}>{title}</p>
            </div>
            <div className={styles.sideDrawerToolbar} >
                {children}
            </div>
        </div>
    );
}

SideDrawerHeader.defaultProps = {
    title: 'Side Drawer',
    titleIcon: 'image'
};

export default SideDrawerHeader;