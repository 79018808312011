import React from "react";
import PropTypes from "prop-types";
import { getSizeWithUnit } from "../utils/UploaderUtils";
import styles from "../file-upload.module.scss";
import { PROCESSING } from "../const/uploader";

export const FileTitle = ({
  fileName,
  fileSize,
  uploadProgress,
  children,
  fileSizeTextStyle,
  FileNameTextStyle,
  FileNameStyle,
  uploadStatus,
}) => {
  let uploadedProgressWIthUnit = getSizeWithUnit(uploadProgress);
  let fileSizeWithUnit = getSizeWithUnit(fileSize);
  const getSizeLabel = () => {
    return uploadProgress === fileSize
      ? fileSizeWithUnit
      : `${uploadedProgressWIthUnit} of ${fileSizeWithUnit}`;
  };
  return (
    <div className={styles.fileInfo}>
      <div className={FileNameStyle}>
        <label className={`${FileNameTextStyle} ${styles.fileInfoText}`}>
          {fileName}
        </label>
        {children}
      </div>
      {uploadStatus !== PROCESSING && (
        <div className={styles.fileSize}>
          <label className={fileSizeTextStyle}>{getSizeLabel()}</label>
        </div>
      )}
    </div>
  );
};

FileTitle.propTypes = {
  fileSize: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  uploadProgress: PropTypes.number.isRequired,
  uploadStatus: PropTypes.string,
  fileSizeTextStyle: PropTypes.string,
  FileNameTextStyle: PropTypes.string,
  FileNameStyle: PropTypes.string,
  children: PropTypes.object,
};

FileTitle.defaultProps = {
  FileNameTextStyle: "",
  FileNameStyle: styles.fileName,
  fileSizeTextStyle: "",
};

export default FileTitle;
