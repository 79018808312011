import React, { useState, useCallback,FunctionComponent } from 'react';
import cx from 'classnames';
import styles from "./_multiselect.module.scss";
type Props = {
  className?: string;
  disabled?: boolean;
  options: string[];
  id?: string;
  isInvalid?: boolean;
  name: string;
  onDisciplineBlur?: () => void;
  onDisciplineChange: (payload: string[]) => void;
  selectedValues: string[];
  size?: number;
  errorMessage?: string;
};

const FormMultiSelect:FunctionComponent <Props> = ({
  id,
  size,
  className,
  disabled,
  onDisciplineChange,
  isInvalid,
  options,
  selectedValues,
  onDisciplineBlur,
  name,
  errorMessage
}) => {
  const [value, setValue] = useState<string[]>(selectedValues);
  const parentClasses = cx(`${styles.gr_select_container}`, className, isInvalid && `${styles.error}`);
  const childClasses = cx(`${styles.gr_select}`, className, isInvalid && `${styles.gr_select_error}`);

  const handleChange = useCallback(
    event => {
      const data = [...event.target.selectedOptions].map(opt => opt.value);
      setValue(data);
      onDisciplineChange(data);
    },
    [onDisciplineChange, setValue]
  );

  const handleBlur = useCallback(() => {
    if (onDisciplineBlur) {
      onDisciplineBlur();
    }
  }, [onDisciplineBlur]);

  const setOption = (data: string[]) => {
    return data.map((val, index) => {
      return (
        <option value={val} key={index}>
          {val}
        </option>
      );
    });
  };

  const handleMouseDown = useCallback(
    event => {
      event.preventDefault();
      const select = event.target.parentNode;
      const scroll = select.scrollTop;
      event.target.selected = !event.target.selected;
      setTimeout(() => {
        select.scrollTop = scroll;
      }, 0);
      event.target.parentNode.focus();
      const values: string[] =
        value && event.target.parentNode.options
          ? value.filter.call(event.target.parentNode.options, (o: any) => o.selected).map((o: any) => o.value)
          : value;
      setValue(values);
      onDisciplineChange(values);
    },
    [onDisciplineChange, setValue, value]
  );

  const handleMouseMove = (event: React.MouseEvent<HTMLSelectElement, MouseEvent>) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="gr-form-element">
        <div className={parentClasses}>
          <select
            className={childClasses}
            value={value}
            onBlur={handleBlur}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onChange={handleChange}
            disabled={disabled}
            multiple
            size={size}
            id={id}
            name={name}>
            {setOption(options)}
          </select>
        </div>
        <div className={styles.error_state}>
          <svg focusable="false" className={styles.icon_18} aria-hidden="true">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#warning-18" />
          </svg>
  <span className={styles.gr_meta}>{errorMessage}</span>
        </div>
      </div>
    </>
  );
};

FormMultiSelect.defaultProps = {
  isInvalid: false,
  disabled: false,
  selectedValues: [],
  errorMessage:'Choose at least 1 discipline'
};

export default FormMultiSelect;
