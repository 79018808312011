import React, { FunctionComponent } from "react";
import ProgressBar from "../ProgressBar_2";
import ProgressCircle from "./ProgressCircle.tsx";

enum progressTypes {
  progressBar = "progress_bar",
  progressCircle = "progress_circle",
}

type Props = {
  type: progressTypes;
  percentage: number;
  progressData: any;
};

const CardProgress: FunctionComponent<Props> = (props) => {
  return props.type === progressTypes.progressBar ? (
    <ProgressBar percentage={props.percentage} />
  ) : (
    <ProgressCircle
      avgCorrect={props.progressData.avgCorrect}
      idBase={props.progressData.idBase}
      bottomLabel={props.progressData.bottomLabel}
      customSizing={props.progressData.customSizing}
      circleDiameter={props.progressData.circleDiameter}
      outerLabel={props.progressData.outerLabel}
    />
  );
};

CardProgress.defaultProps = {
  type: progressTypes.progressBar,
};

export default CardProgress;
