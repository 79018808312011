import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../Icon'
import FileUploadingView from './FileUploadingView'
import styles from '../file-upload.module.scss'
import UploaderContext from '../containers/UploaderContext'
import { OPEN } from '../const/uploader'
export const MultipleFileUpload = ({ deleteFiles, children, modalEnabled, hideRemoveButton}) => {
  const context = useContext(UploaderContext)
  return (
    <div className={styles.multipleFileUploaderModal}>
      <div id={`rectangle-${context.state.id}`} className={!modalEnabled ? styles.modalRectangle : styles.rectangle}>
        {children}
        {context.state.errorMessage !== '' && modalEnabled &&
          <span role='alert' aria-live='assertive' aria-atomic='true' aria-relevant='text'
            className={styles.inlineErrorMessage}>
            <Icon fileName='warning-18' name={'error message'} title='error message' />
            <p>{context.state.errorMessage}</p>
          </span>
        }
      </div>
      { context.state.uploadingViewStatus === OPEN &&
      <FileUploadingView deleteFilesCallback={deleteFiles} hideRemoveButton={hideRemoveButton} /> }
    </div>
  )
}

MultipleFileUpload.propTypes = {
  deleteFiles: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  modalEnabled: PropTypes.bool.isRequired
}

export default MultipleFileUpload
