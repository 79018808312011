/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2018 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./multiLine.module.scss";
import Icon from "../Icon";
import { generateId } from "../_util/common.js";
class MultiLineText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: props.initialValue,
      error: false,
      errorMessage: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.errorStateUpdate = this.errorStateUpdate.bind(this);
  }

  errorStateUpdate(prevState) {
    this.setState((prevState) => ({
      errorMessage: this.props.errorMessage,
      error: !(this.state.error && this.props.errorMessage === ""),
    }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.errorStateUpdate(prevProps);
    }
  }

  handleChange(event) {
    this.setState({ inputText: event.target.value }, () => {
      this.props.getMultiTextValue(this.state.inputText);
      this.props.inputValidation(this.state.inputText);
    });
  }

  render() {
    return (
      <div className={styles.multiLineContainer} aria-label={this.props.label}>
        {this.props.label != null && (
          <label htmlFor={this.props.id} className={styles.lsLabel}>
            {this.props.label}
          </label>
        )}

        <textarea
          id={this.props.id}
          style={{ width: this.props.width, height: this.props.height }}
          className={`${styles.lsTextarea} 
                      ${this.props.isDisabled && `${styles.lsDisabled}`} 
                      ${this.props.readOnly && `${styles.lsReadOnly}`} 
                      ${this.state.error && `${styles.lsErrorTextArea}`}`}
          onChange={(event) => {
            this.handleChange(event);
          }}
          value={this.props.value ? this.props.value : this.state.inputText}
          readOnly={this.props.readOnly}
          disabled={this.props.isDisabled}
          placeholder={this.props.placeholder}
          aria-describedby={`${this.props.id + "-info"} ${this.state.error ? this.props.id + "-error" : ''}`}
        />
        {this.props.infoText && (
          <div className={styles.lsInfoText} id={this.props.id + "-info"}>{this.props.infoText}</div>
        )}
        {this.state.error && (
          <p
            className={styles.lsErrorMessage}
            id={this.props.id + "-error"}>
            <span>
              <Icon
                isHidden={true}
                fileName="warning-16"
                name={this.props.id + "-error"}
                title="Error"
              />
              <label>{this.state.errorMessage}</label>
            </span>
          </p>
        )}
      </div>
    );
  }
}
MultiLineText.propTypes = {
  errorMessage: PropTypes.string,
  getMultiTextValue: PropTypes.func,
  height: PropTypes.string,
  id: PropTypes.string,
  infoText: PropTypes.string,
  inputValidation: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  width: PropTypes.string,
  value: PropTypes.string,
  initialValue: PropTypes.string,
};

MultiLineText.defaultProps = {
  width: "350px",
  height: "80px",
  isDisabled: false,
  readOnly: false,
  placeholder: "",
  errorMessage: "",
  id: generateId(),
  initialValue: "",
};

export default MultiLineText;
