import React, { FunctionComponent } from "react";
import styles from "./card.module.scss";

type Props = {
  url: string;
  alt?: string;
  onlyImage?: boolean;
  width?: string;
};

const CardImage: FunctionComponent<Props> = (props) => {
  return (
    <div
      className={
        props.onlyImage
          ? styles.card_image_container
          : styles.card_image_container_169
      }
      style={{ width: props.width }}
    >
      <img alt={props.alt} className={styles.card_image} src={props.url}></img>
    </div>
  );
};

CardImage.defaultProps = {
  onlyImage: false,
  width: "100%",
};

export default CardImage;
