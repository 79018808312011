import React, { FunctionComponent } from "react";
import styles from './avatar.module.scss';

type Props = {
  firstName: string;
  lastName: string;
};

const Avatar: FunctionComponent<Props> = (props) => {
  return (
    <div className={styles.avatar}>
      <p className={styles.avatar_text}>{`${props.firstName.charAt(0)}${props.lastName.charAt(0)}`}</p>
    </div>
  );
};

export default Avatar;
