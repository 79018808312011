import React, { FunctionComponent } from "react";
import styles from "./table.module.scss";
import Pagination from "../Pagination";
import DropDownList from "../DropDownList";

type Option = {
  value: number;
  display: number;
};

enum initialPages {
  four = 4,
  eight = 8,
}

enum Theme {
  default = "default",
  wsol = "wsol",
}

type Props = {
  totalPages: number;
  currentPage: number;
  selectedOption: number;
  optionList: Option[];
  rowType: string;
  totalRows: number;
  onSelectDropdown: (selectedOption: number) => void;
  onSelectPagination: (selectedPage: number) => void;
  theme: Theme;
};

const TablePagination: FunctionComponent<Props> = (props) => {
  return (
    <div className={styles[`table_${props.theme}_footer`]}>
      <div className={styles[`table_${props.theme}_footer_dropdown`]}>
        <p className={styles[`table_${props.theme}_footer_total`]}>
          Viewing {props.totalRows} {props.rowType}
        </p>
        <div
          className={styles[`table_${props.theme}_footer_paginationDropdown`]}
        >
          <p
            className={
              styles[`table_${props.theme}_footer_paginationDropdown_label`]
            }
          >
            {props.theme === "wsol" ? "students per page" : "Rows per page:"}
          </p>
          <DropDownList
            className={
              styles[`table_${props.theme}_footer_paginationDropdown_drop`]
            }
            id={`rowDropdown_${Date.now()}`}
            label={props.selectedOption}
            triggerType="box"
            boxWidth="75px"
            menuWidth="160px"
            selectedOption={props.selectedOption.toString()}
            isCheckboxEnabled={true}
            onGetSelectedOption={props.onSelectDropdown}
            options={props.optionList}
          />
        </div>
      </div>

      <Pagination
        type={
          initialPages.four * 2 - 2 <= props.totalPages
            ? "FASTFORWARD"
            : "MAXIMUM"
        }
        currentPageNum={props.currentPage}
        totalPages={props.totalPages}
        handlePage={props.onSelectPagination}
        initialPageCount={
          props.totalPages <= initialPages.four
            ? props.totalPages
            : initialPages.four
        }
      />
    </div>
  );
};

export default TablePagination;
