import React, { useContext } from 'react'
import UploadingProgressLabelContainer from './UploadingProgressLabel'
import Icon from '../../../Icon'
import { CLOSE, OPEN } from '../const/uploader'
import { setFocusToElement } from '../utils/UploaderUtils'
import styles from '../file-upload.module.scss'
import UploaderContext from '../containers/UploaderContext'

export const FileMinimizer = () => {
  const context = useContext(UploaderContext)
  /**
   * Display the file upload modal and close the minimizer view
   */
  const handleMaximize = () => {
    context.setUploader(OPEN)
    context.setUploadMinimizerView(CLOSE)
    setFocusToElement('MinimizeButton')
  }
  /**
   * close both uploader and minimizer views
   */
  const handleClose = () => {
    context.setUploader(CLOSE)
    context.setUploadMinimizerView(CLOSE)
  }
  return (
    <div className={styles.minimizer}>
      <UploadingProgressLabelContainer />
      <div className={styles.minimizeIcons}>
        <div className={styles.trimMinimize}>
          <button
            id={`MaximizeButton-${context.state.id}`}
            className={styles.iconBtn}
            aria-label='maximize the file uploader'
            onClick={() => handleMaximize()}
          >
            <Icon fileName='maximize-24' name={'maximize the file uploader'} title='maximize the file uploader' />
          </button>
        </div>
        <div className={styles.trimClose}>
          <button
            id={`CloseButton-${context.state.id}`}
            className={styles.iconBtn}
            aria-label='close the file uploader'
            onClick={() => handleClose()}
          >
            <Icon fileName='close-24' name={'close the file uploader'} title='close the file uploader' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default FileMinimizer
