/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2018 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import React from 'react'
import { func, string, array, number, bool, object } from 'prop-types'
import styles from '../file-upload.module.scss'
import FileUploaderView from './FileUploaderView'
import SingleFileUpload from './SingleFileUpload'
import { getSizeWithUnit, setFocusToElement, compareFileListArrays } from '../utils/UploaderUtils'
import {
  OPEN, DEFAULT_MAXIMUM_FILE_SIZE, FILE_UPLOAD_ERR_MSG, GENERAL_ERR_MSG, UNSUPPORTED_CHAR,
  ACCEPTED_FILE_TYPES_LABEL, MAX_FILE_SIZE_LABEL, MAX_FILE_AMOUNT_LABEL, BROWSE, UPLOAD_LABEL, CLOSE
} from '../const/uploader'
import FileUploadModal from './FileUploadModal'
import MultipleFileUpload from './MultipleFileUpload'
import FileMinimizer from './FileMinimizer'
import UploaderContext from '../containers/UploaderContext'

class FileUpload extends React.Component {
  static contextType = UploaderContext
  constructor (props) {
    super(props)
    this.state = {
      loadingFile: false,
      currentDragOver: false
    }
    this.onDragEnter = this.onDragEnter.bind(this)
    this.setScrollableHeight = this.setScrollableHeight.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.processFileChange = this.processFileChange.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.handleUserUploadCompleteCallback = this.handleUserUploadCompleteCallback.bind(this)
    this.hasExtension = this.hasExtension.bind(this)
    this.isFileListValid = this.isFileListValid.bind(this)
    this.getFileAllowedLabel = this.getFileAllowedLabel.bind(this)
    this.getFileUploadHtmlContent = this.getFileUploadHtmlContent.bind(this)
  }

  componentDidMount () {
    /** set the initial file List**/
    const { updateFileData, setUploadingView, setUploaderId } = this.context
    updateFileData(this.props.fileList)
    setUploaderId(this.props.id)
    if (!this.props.isSingleFileUpload && this.props.fileList.length > 0) {
      setUploadingView(OPEN)
    } else {
      setUploadingView(CLOSE)
    }
  }

  componentDidUpdate (prevProps) {
    /** set the file upload status if the status is singleFileuploader */
    const { updateFileData, setSingleFileUpload, setUploadingView, state } = this.context
    if (this.props.isSingleFileUpload && this.props.fileList.length === 1 && state.singleFileUploadStatus === CLOSE) {
      setSingleFileUpload(OPEN)
    }
    /** compare the prev file list and the latest before updating the state */

    if (compareFileListArrays(prevProps.fileList, this.props.fileList)) {
      updateFileData(this.props.fileList)
      /** open the file uploading view if an initial file list is sent by the consumer */
      if (prevProps.fileList.length === 0 && this.props.fileList.length > 0 &&
        !this.props.isSingleFileUpload) {
        setUploadingView(OPEN)
        if (state.uploadMinimizerViewStatus !== OPEN) this.setScrollableHeight(false)
      }
    }
    /** set the focus to the delete icon if the property focused is true */
    if (this.props.fileList.length > 0) {
      let focusedElement = this.props.fileList.find(file => file.focused)
      if (focusedElement) {
        let elementId = `delete-${focusedElement.fileId}`
        setFocusToElement(elementId)
      }
    } else {
      if (state.uploadingViewStatus === OPEN) setUploadingView(CLOSE)
    }
  }

  /**
   * method for event handling the on drag enter event
   */
  onDragEnter () {
    this.setState({
      currentDragOver: true
    })
  }

  /**
   * method for event handling the on drop event
   * @param files
   */
  onDrop (files) {
    const { setErrorMessage } = this.context
    this.setState({
      currentDragOver: false
    })
    setErrorMessage('')
    this.handleUserUploadCompleteCallback(files)
  }

  /**
   * handle the file upload or drag
   * @param e
   */
  handleFileChange (e) {
    const { setErrorMessage } = this.context
    setErrorMessage('')
    this.handleUserUploadCompleteCallback(e.target.files)
    e.target.value = ''
    e.preventDefault()
  }
  /**
   * handle file upload or drop if the user has passed a function to handle it
   * @param files
   */
  processFileChange (files) {
    let fileList = []
    if (this.props.handleChange != null) {
      this.props.handleChange(files)
    } else {
      for (const key of Object.keys(files)) {
        fileList.push(files[key])
      }
    }
    return { fileList, errorMessage : this.isFileListValid(files) }
  }

  /**
   * this will change the height of the file list to enable
   * the scroll to the new height after the additional of new files
   * @param errModal
   */
  setScrollableHeight (errModal) {
    const { state } = this.context
    let listHeight = !this.props.isSingleFileUpload && document.getElementById('rectangle')
      ? document.getElementById(`rectangle-${state.id}`).scrollHeight + 'px' : ''
    if (document.getElementById(`fileList-${state.id}`) && this.props.fileList.length > 4) {
      document.getElementById(`fileList-${state.id}`).style.height = !errModal && !this.props.isSingleFileUpload
        ? listHeight : document.getElementById(`rectangle-${state.id}`).scrollHeight + 'px'
    }
  }

  /**
   * handle the callback after all files are uploaded
   * check whether all the promises are resolved and send files
   * set the error message
   * array to the consumer
   * @param files
   */
  handleUserUploadCompleteCallback (files) {
    const { setSingleFileUpload, setUploadingView, state, setErrorMessage } = this.context
    let noOfFiles = files.length
    if (this.props.maximumFileCount >= noOfFiles) {
      let processedData = this.processFileChange(files)
      if (this.props.isSingleFileUpload && noOfFiles === 1 && processedData.errorMessage === '') {
        setSingleFileUpload(OPEN)
      } else {
        if (processedData.errorMessage === '' && noOfFiles >= 1) {
          setUploadingView(OPEN)
        }
      }
      let errModal = processedData.errorMessage !== ''
      this.props.getFiles(errModal ? [] : processedData.fileList)
      this.props.getError(processedData.errorMessage)
      if (state.uploadingViewStatus === OPEN) this.setScrollableHeight(!errModal)
    } else {
      let errorMessage = this.props.errorLoadingErrorMessages &&
      this.props.errorLoadingErrorMessages.fileMaxCountErrMsg ? this.props.errorLoadingErrorMessages.fileMaxCountErrMsg
        : FILE_UPLOAD_ERR_MSG
      setErrorMessage(errorMessage)
      this.props.getFiles([])
      this.props.getError(errorMessage)
    }
  }

  /**
   * method to handle the on drag leave event
   */
  onDragLeave () {
    this.setState({
      currentDragOver: false
    })
  }

  /**
   * check whether the files list is valid in size and extension
   * and return the error messages if any available
   * @param files
   * @returns {string}
   */
  isFileListValid (files) {
    let errorMessage = ''
    const { setErrorMessage } = this.context
    for (const key of Object.keys(files)) {
      let file = files[key]
      const fileName = file ? file.name : ''
      const ext = this.hasExtension(fileName, this.props.allowedFileTypes)
      const fileExtention = this.getExtension(fileName)
      let maxFileSizeBytes = this.props.maxFileSizeBytes
      if ((file && ((fileExtention && this.props.maxFileTypeSizeBytes && this.props.maxFileTypeSizeBytes[fileExtention] && file.size > this.props.maxFileTypeSizeBytes[fileExtention]) || ((!this.props.maxFileTypeSizeBytes || (this.props.maxFileTypeSizeBytes && fileExtention && !this.props.maxFileTypeSizeBytes[fileExtention])) && file.size > maxFileSizeBytes))) || !ext) {
        if (file && ((fileExtention && this.props.maxFileTypeSizeBytes && this.props.maxFileTypeSizeBytes[fileExtention] && file.size > this.props.maxFileTypeSizeBytes[fileExtention]) || ((!this.props.maxFileTypeSizeBytes || (this.props.maxFileTypeSizeBytes && fileExtention && !this.props.maxFileTypeSizeBytes[fileExtention])) && file.size > maxFileSizeBytes))) {
          errorMessage = this.props.errorLoadingErrorMessages && this.props.errorLoadingErrorMessages.fileMaxSizeErrMsg
            ? this.props.errorLoadingErrorMessages.fileMaxSizeErrMsg : FILE_UPLOAD_ERR_MSG
        } else if (!ext) {
          errorMessage = this.props.errorLoadingErrorMessages && this.props.errorLoadingErrorMessages.fileTypeErrMsg
            ? this.props.errorLoadingErrorMessages.fileTypeErrMsg : FILE_UPLOAD_ERR_MSG
        }
      } else if (!ext) {
        errorMessage = this.props.errorLoadingErrorMessages && this.props.errorLoadingErrorMessages.generalErrMsg
          ? this.props.errorLoadingErrorMessages.generalErrMsg : GENERAL_ERR_MSG
      }

      // Check unsupported characters
      let format = /[\n*?\"<>|#:]/
      if (this.props.validateCharactersInFilename && format.test(fileName)) {
        errorMessage = this.props.unsupportedCharactersErrorMessage
      }
      // End check unsupported...

      if (errorMessage !== '') break
    }
    setErrorMessage(errorMessage)
    return errorMessage
  }

  /**
   * create and return the guideline to be displayed
   * with max size and file count
   */
  getFileAllowedLabel () {
    let maxFileSizeAndCountDefaultLabel = `${MAX_FILE_AMOUNT_LABEL + this.props.maximumFileCount} ` +
    `| ${MAX_FILE_SIZE_LABEL +
    getSizeWithUnit(this.props.maxFileSizeBytes)}`

    let allowedSize = maxFileSizeAndCountDefaultLabel
    if(this.props.allowedFileSizeText){
      allowedSize = this.props.allowedFileSizeText
    }

    let fileTypesLabel = ''
    this.props.allowedFileTypes.map((type, index) => {
      fileTypesLabel = index === this.props.allowedFileTypes.length - 1 ? fileTypesLabel + type
        : `${type}, ${fileTypesLabel}`
    })
    let filesAllowedDefaultLabel = `${ACCEPTED_FILE_TYPES_LABEL + fileTypesLabel} \n`

    let allowedFileText = filesAllowedDefaultLabel

    if(this.props.allowedFileTypeText){
      allowedFileText = this.props.allowedFileTypeText
    }
    
    let htmlContent = !this.props.filesAllowedLabel
      ? <div>
        <div><label className={styles.filesAllowedDefaultLabel} htmlFor={`fileUpload_${this.props.id}`} aria-label={`${allowedFileText},`} >
          {allowedFileText}
        </label></div>
        <div className={styles.maxFileSizeAndCountDefaultLabel} >
          <label htmlFor={`fileUpload_${this.props.id}`} >{allowedSize}</label>
        </div>
      </div> : <div><label htmlFor={`fileUpload_${this.props.id}`} >{this.props.filesAllowedLabel}</label></div>
    return htmlContent
  }

  /**
   * compare the consumer given extensions with the uploaded file extension
   * @param inputID
   * @param extensions
   * @returns {boolean}
   */
  hasExtension (inputID, extensions) {
    return new RegExp('(' + extensions.join('|').replace(/\./g, '\\.') + ')$').test(inputID.toLowerCase())
  }

  /**
   * compare the consumer given extensions with the uploaded file extension
   * @param fileName
   * @returns {string}
   */
  getExtension(fileName) {
    return fileName.split('.').pop()
  }

  /**
   * return component for file upload
   * @returns {XML}
   */
  getFileUploadHtmlContent () {
    let isBrowseBtnDisabled = this.props.maximumFileCount === 0
    return (
      <div>
        <FileUploaderView onDrop={this.onDrop} onDragLeave={this.onDragLeave} onDragEnter={this.onDragEnter}
          buttonLabel={this.props.buttonLabel} fileTypes={this.props.allowedFileTypes}
          handleFileChange={this.handleFileChange} currentDragOver={this.state.currentDragOver}
          filesAllowedLabel={this.getFileAllowedLabel()} isBrowseBtnDisabled={isBrowseBtnDisabled}
          modalEnabled={this.props.modalEnabled} isSingleFileUpload={this.props.isSingleFileUpload}
          dropFileText={this.props.dropFileText} chooseFileText={this.props.chooseFileText}
        />
      </div>
    )
  }

  render () {
    const { state } = this.context
    let uploadingViewStatus = state.uploadingViewStatus === OPEN
    let wrapperDivClass = state.uploadMinimizerViewStatus === OPEN ? '' : !this.props.modalEnabled
      ? styles.wrapperWithoutModal : uploadingViewStatus ? styles.wrapper
        : styles.wrapperFirst
    return (
      (state.uploadMinimizerViewStatus === OPEN || state.uploaderStatus === OPEN) &&
      <div className={wrapperDivClass}>
        { state.uploadMinimizerViewStatus === OPEN && <FileMinimizer />}
        { state.uploaderStatus === OPEN && ((
          !this.props.isSingleFileUpload && this.props.modalEnabled &&
          <FileUploadModal uploadLabel={this.props.uploadLabel} cancelFiles={this.props.cancelFiles}
            minimizeIconEnabled={this.props.minimizeIconEnabled} doneUpload={this.props.doneUpload}
            closeModal={this.props.closeModal} applyOverlay={this.props.applyOverlay}
            keepOverlay={this.props.keepOverlay}>
            <MultipleFileUpload deleteFiles={this.props.deleteFiles} modalEnabled={this.props.modalEnabled} hideRemoveButton={this.props.hideRemoveButton}>
              {this.getFileUploadHtmlContent()}
            </MultipleFileUpload>
          </FileUploadModal>) || (
          !this.props.isSingleFileUpload && !this.props.modalEnabled &&
          <MultipleFileUpload deleteFiles={this.props.deleteFiles} modalEnabled={this.props.modalEnabled}>
            {this.getFileUploadHtmlContent()}
          </MultipleFileUpload>) || (
          this.props.isSingleFileUpload &&
          <SingleFileUpload deleteFiles={this.props.deleteFiles} fileCount={state.files.length}
            singleFileUploadStatus={state.singleFileUploadStatus} hideRemoveButton={this.props.hideRemoveButton}>
            {this.getFileUploadHtmlContent()}
          </SingleFileUpload>
        ))
        }
      </div>)
  }
}

FileUpload.propTypes = {
  /** consumer props-start */
  allowedFileTypes: array.isRequired, // array of file types allowed.  Used for confirming the extension is correct
  id: string.isRequired, // unique id
  fileList: array.isRequired, // fileList to render
  getFiles: func.isRequired, // call back to send file information
  getError: func.isRequired, // call back to send any error
  modalEnabled: bool.isRequired, // value handles modal hide and visibility
  maximumFileCount: number.isRequired, // number of files that can be updated
  deleteFiles: func.isRequired, // call back for a file deletion
  isSingleFileUpload: bool.isRequired, // value hold whether the uploader is a single or multiple file uploader
  maxFileSizeBytes: number, // maximum file size allowed for this
  maxFileTypeSizeBytes: object, // maximum file size allowed for different file types
  dropFileText: string, // first upload guide text for user
  chooseFileText: string, // second upload guide text for user
  allowedFileTypeText: string, // third guide text for all file types
  uploadLabel: string, // label above the component
  buttonLabel: string, // label for the browse button
  handleChange: func, // handle uploader function fully by consumer
  allowedFileSizeText: string, // final guide text for allowed file size
  validateCharactersInFilename: string, // Enable validation of special characters in the file name
  unsupportedCharactersErrorMessage: string, // Error message for unsupported characters in the file name
  errorLoadingErrorMessages: object, // error message if the file is incorrect.
  // Shown inline this should be an object of error messages fileUploadErrMsg, generalErrMsg
  // {fileMaxCountErrMsg : '', generalErrMsg: '', fileMaxSizeErrMsg: '', fileTypeErrMsg: ''}
  cancelFiles: func, // call back for file cancellation, this is applicable only for modal enabled uploader
  doneUpload: func, // callback for done button onclick
  closeModal: func, // callback for close modal
  filesAllowedLabel: string, // message to the user for the files allowed
  minimizeIconEnabled: bool, // value to hold whether minmize icon is enabled/disabled
  applyOverlay: bool,
  keepOverlay: bool
  /** consumer props-end */
}

FileUpload.defaultProps = {
  buttonLabel: BROWSE,
  uploadLabel: UPLOAD_LABEL,
  minimizeIconEnabled: true,
  getFiles: () => {/**/},
  getError: () => {/**/},
  cancelFiles: () => {/**/},
  doneUpload: () => {/**/},
  closeModal: () => {/**/},
  fileList: [],
  maxFileSizeBytes: DEFAULT_MAXIMUM_FILE_SIZE,
  applyOverlay: false,
  keepOverlay: false,
  validateCharactersInFilename: false,
  unsupportedCharactersErrorMessage: UNSUPPORTED_CHAR
}

export default FileUpload
