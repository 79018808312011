// Removed scaling functionality
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class QuestionPrompt extends Component {
  render () {
    const { questionId, prompt } = this.props

    return (
      <div className='question-prompt'>
        <div className='question-text' id={`${questionId}-prompt`}
          dangerouslySetInnerHTML={{ __html: prompt }} />
      </div>
    );
  }
}

QuestionPrompt.propTypes = {
  questionId: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired
}

export default QuestionPrompt;
