import React from 'react'
import PropTypes from 'prop-types'
import SingleFileUploadStatus from './SingleFileUploadStatus'
import { CLOSE, OPEN } from '../const/uploader'
export const SingleFileUpload = ({ children, deleteFiles, fileCount, singleFileUploadStatus, hideRemoveButton }) => {
  return (
    <div>
      {singleFileUploadStatus === CLOSE && children}
      { fileCount === 1 && singleFileUploadStatus === OPEN &&
        <SingleFileUploadStatus deleteFilesCallback={deleteFiles}  hideRemoveButton={hideRemoveButton}/>
        }
    </div>
  )
}

SingleFileUpload.propTypes = {
  deleteFiles: PropTypes.func.isRequired,
  fileCount: PropTypes.number.isRequired,
  singleFileUploadStatus: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  hideRemoveButton: PropTypes.bool.isRequired
}

export default SingleFileUpload
