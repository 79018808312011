import React from "react";
import ReactDOM from "react-dom";
import Alert from '../Alert/index'
import styles from './message.module.scss';
class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.isContainerAlreadyThere = document.getElementById("alert_boxes_id");
        if (!this.isContainerAlreadyThere) {
            this.overlayContainer = document.createElement("div");
            this.overlayContainer.setAttribute("id", "alert_boxes_id");
            document.body.appendChild(this.overlayContainer);

        } else {
            this.overlayContainer = this.isContainerAlreadyThere;
        }
        this.remove_message_box()
    }
    componentWillUnmount() {
        document.body.removeChild(this.overlayContainer);
    }
    remove_message_box = () => {
        if(this.props.closeTime !== false){
            setTimeout(() => {
                var list = document.getElementById("alert_boxes_id");
                if(list.childNodes.length > 0)
                    list.removeChild(list.childNodes[0]);
            }, this.props.closeTime !== undefined && Number.isInteger(this.props.closeTime) ? this.props.closeTime : 5000);
        }
    }

    close_message_box = (id) =>{
            document.getElementById(`d-default-alert${id}`).remove();
    }
 

    check_alert_type = () => {
        const message_list = ["Default", "Success", "Danger"]
        if (this.props.variant !== undefined && message_list.includes(this.props.variant)) {
            let id =Math.floor(Math.random() * 10)
            return (<Alert
                variant={`${this.props.variant}`}
                body={`${this.props.body !== undefined ? this.props.body : "This is a deafult alert message"}`}
                heading={`${this.props.heading !== undefined ? this.props.heading : "Default alert"}`}
                id={`d-default-alert${id}`}
                onClose={() => this.close_message_box(id)}
                className={`${styles.message_box} custom-alert-position`} >
            </Alert>);
        } else {
            return (<Alert
                variant="Default"
                body="This is a deafult alert message"
                heading="Default alert"
                id="d-default-alert"
                onClose={() => console.log("Close alert")}
                className={`${styles.message_box} custom-alert-position`} >
            </Alert>)
        }
    }
    render() {
        return ReactDOM.createPortal(
            this.check_alert_type(),
            this.overlayContainer
        );
    }
}

export default MessageBox;
