import React, { FunctionComponent } from "react";
import styles from "./card.module.scss";
import Container from "./../Common/Container.tsx";

interface Props {
  align?: string;
  layout?: string;
  columnstart?: number;
  columnend?: number;
  className?: string;
}

const CardFooter: FunctionComponent<Props> = (props) => {
  return (
    <Container
      align={props.align}
      layout={props.layout}
      columnstart={props.columnstart}
      columnend={props.columnend}
      className={`${styles.card_footer} ${props.className}`}
    >
      {props.children}
    </Container>
  );
};

CardFooter.defaultProps = {
  align: "row",
  layout: "space_between_center",
};

export default CardFooter;
