/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import CollapsibleRowTableHeader from "./CollapsibleRowTableHeader.tsx";
import Icon from "./../Icon";
import styles from "./table.module.scss";

type Column = {
  title: string;
  dataIndex: string;
  key: number;
  contentAlign?: Align;
  fontWeight?: string;
  titleAlign?: Align;
};

type Row = {
  key: number;
  expandedContent?: string;
};

enum Align {
  center = "center",
  left = "left",
  right = "right",
}

enum ExpandedItemsAtOnce {
  one = "one",
  multiple = "multiple",
}

type Props = {
  columns: Column[];
  dataSource: Row[];
  onClickRow?: (data: Row) => null;
  expandedItemsAtOnce?: ExpandedItemsAtOnce;
  rowColor?: string;
};

type State = {
  columns: Column[];
  dataSource: Row[];
  dataSourceChunk?: Row[];
  expanded: number[];
};

class CollapsibleRowTable extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      dataSource: props.dataSource,
      expanded: [],
    };
  }

  checkExpanded = (key: number) => {
    return this.state.expanded.includes(key);
  };

  onClickRow = (data: Row) => {
    const isExpanded = this.checkExpanded(data.key);
    let expandedClone = [...this.state.expanded];
    if (isExpanded) {
      expandedClone = expandedClone.filter((item) => item !== data.key);
    } else if (this.props.expandedItemsAtOnce === ExpandedItemsAtOnce.one) {
      expandedClone = [data.key];
    } else {
      expandedClone.push(data.key);
    }

    this.setState({
      expanded: expandedClone,
    });
  };

  renderTableRow = (data: Row, indexMain: number) => {
    const expanded = this.checkExpanded(data.key);
    const cells = this.props.columns.map((col, index) => {
      return (
        <td
          className={styles.table_body_cell}
          key={index}
          align={col.contentAlign}
        >
          {data[col.dataIndex]}
        </td>
      );
    });
    const row = (
      <>
        <tr
          onClick={() => this.onClickRow(data)}
          className={
            expanded ? styles.table_body_row_expanded : styles.table_body_row
          }
          key={indexMain}
          tabIndex={this.props.onClickRow ? 0 : -1}
          role={this.props.onClickRow ? "button" : ""}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              this.props.onClickRow(data);
            }
          }}
        >
          {cells}
          <td
            className={`${styles.table_body_cell} ${styles.table_body_cell_icon}`}
            key={indexMain}
          >
            <Icon
              fileName={expanded ? "collapse-18" : "expand-18"}
              title={expanded ? "collapse item" : "expand item"}
              focusable={false}
            />
          </td>
        </tr>
        {expanded && (
          <tr className={styles.table_body_row_expanded_area}>
            <td></td>
            <td
              className={`${styles.table_body_cell_expanded}`}
              colSpan={this.props.columns.length}
            >
              <div className={`${styles.table_body_cell_expanded_container}`}>
                {data.expandedContent}
              </div>
            </td>
          </tr>
        )}
      </>
    );
    return row;
  };

  renderBody = () => {
    return this.state.dataSource.map((data, index) => {
      return <>{this.renderTableRow(data, index)}</>;
    });
  };

  render() {
    return (
      <table className={`${styles.table} ${this.props.className}`}>
        <CollapsibleRowTableHeader
          columns={this.state.columns}
        ></CollapsibleRowTableHeader>
        <tbody style={{ backgroundColor: this.props.rowColor }}>
          {this.renderBody()}
        </tbody>
      </table>
    );
  }

  public static defaultProps = {
    onClickRow: (data: Row) => {
      console.log("Row clicked", data);
    },
    expandedItemsAtOnce: ExpandedItemsAtOnce.one,
  };
}

export default CollapsibleRowTable;
