import React, { useContext } from 'react'
import UploaderContext from '../containers/UploaderContext'
import styles from '../file-upload.module.scss'
export const UploadingProgressLabel = () => {
  const context = useContext(UploaderContext)
  let progressLabel =
    `Uploading  (${context.state.doneFileCount} done, ${context.state.inProgressFileCount} in progress)`
  return (
    <h3 className={styles.uploadingDone} role='alert' aria-live='assertive' aria-atomic='true' aria-relevant='text'>
      <div>{progressLabel}</div>
    </h3>
  )
}

export default UploadingProgressLabel
