/** trim the ds auth params from the url */
export const trimDsAuthToken = (url) => {
  const index = url.indexOf("&");
  return index > -1 ? url.slice(0, index) : url;
};

/** remove all parameters on img urls */
export const trimAllDsAuthTokens = (parsedHtml) => {
  const imgElements = parsedHtml.querySelectorAll("img");

  imgElements.forEach((element) => {
    const signedUrl = element.getAttribute("src");
    const url = trimDsAuthToken(signedUrl);
    element.setAttribute("src", url);
  });

  return parsedHtml;
};

/** extract the mathml */
export const getMathMl = (parsedHtml) => {
  const mathElements = parsedHtml.querySelectorAll(".pmml-child");

  mathElements.forEach(function (element) {
    const originalMathContent = JSON.parse(element.getAttribute("pmmldata"));
    const mathElement = parsedHtml.querySelector(
      `.pmml-child.${element.classList[3]}`
    );
    mathElement.innerHTML = originalMathContent;
  });

  return parsedHtml;
};

/** add html structure content */
export const addWrapper = (html) => {
  // Creating and appending the div
  let divElement = document.createElement("div");
  divElement.id = "lass-script-load-div";
  html.head.prepend(divElement);
  //to avoid added duplicate MAthJax scripts
  if(document.querySelector('script[src="https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/v1.2.0/matheditor/mathjax/es5/tex-mml-chtml.js"]') === null) {
    var meta = document.createElement('meta');
    meta.httpEquiv = "Content-Type";
    meta.content = "text/html";
    meta.charset = "UTF-8"
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/v1.2.0/matheditor/mathjax/es5/tex-mml-chtml.js";
    s.async = true
    html.head.append(meta)
    html.head.append(s)  
    const serializer = new XMLSerializer();
    const serializedHtml = serializer.serializeToString(html);
    
    //  Adding required fonts for Script and Fraktur letters
    if (serializedHtml.includes(`font-family: 'Great Vibes', cursive;`)) {
      let font = document.createElement('link');
      font.href = "https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap";
      font.rel = "stylesheet"
      html.head.append(font)
    }
    if (serializedHtml.includes(`font-family: 'UnifrakturMaguntia', cursive;`)) {
      let font = document.createElement('link');
      font.href = "https://fonts.googleapis.com/css2?family=UnifrakturMaguntia&display=swap";
      font.rel = "stylesheet"
      html.head.append(font)
    }
    if (serializedHtml.includes(`font-family: 'Dancing Script', cursive;`)) {
      let font = document.createElement('link');
      font.href = "https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap";
      font.rel = "stylesheet"
      html.head.append(font)
    }
  }
  return `${html.head.outerHTML}
  ${html.body.outerHTML}`
};

/** remove html structure */
export const removeWrapper = (html) => {
  let parser = new DOMParser();
  html = html.replace(/<noscript[\s\S]*?>[\s\S]*?<\/noscript>/gi,'')
  let parsedHtml = parser.parseFromString(html, "text/html");
  var scriptArray2 = [
    `(window.BOOMR_mq=window.BOOMR_mq||[]).push(["addVar",{"rua.upush":"false","rua.cpush":"false","rua.upre":"false","rua.cpre":"false","rua.uprl":"false","rua.cprl":"false","rua.cprf":"false","rua.trans":"","rua.cook":"false","rua.ims":"false","rua.ufprl":"false","rua.cfprl":"false","rua.isuxp":"false","rua.texp":"norulematch"}]);`,
    `!function(e){var n="https://s.go-mpulse.net/boomerang/`,
    `go-mpulse.net/boomerang`,
    `window.BOOMR_mq`
  ]

    // remove Extraneous Code - LAF-7091 fix
    parsedHtml.querySelectorAll('meta').forEach(e => {
      if (e.getAttribute('http-equiv') === 'Content-Type') {
        e.remove();
      }
    });

    parsedHtml.querySelectorAll('script').forEach(e => {
      if (
        e.getAttribute('src') === 'https://learningapps-ui-ppe.pearsoned.com/b0/dev/webcomponents-shared/latest/matheditor/mathjax/es5/tex-mml-chtml.js' ||
        e.getAttribute('src') === 'https://learningapps-ui-ppe.pearsoned.com/b0/qa-int/webcomponents-shared/latest/matheditor/mathjax/es5/tex-mml-chtml.js' ||
        e.getAttribute('src') === 'https://learningapps-ui-ppe.pearson.com/b0/dev/lass-ui-cdn-libs/latest/matheditor/mathjax/es5/tex-mml-chtml.js' ||
        e.getAttribute('src') === 'https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/v1.2.0/matheditor/mathjax/es5/tex-mml-chtml.js' ||
        e.getAttribute('src') === 'https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/latest/matheditor/mathjax/es5/tex-mml-chtml.js' ||
        e.getAttribute('src') === 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js'
      ) {
        e.remove();
      }
    });
    
    // remove Extraneous Code - LAF-7518 fix
    parsedHtml.querySelectorAll(`script`).forEach( e=> {
      if (
        e.innerHTML === scriptArray2[0] ||
        e.innerHTML.startsWith(scriptArray2[1]) ||
        e.innerHTML.includes(scriptArray2[2]) ||
        e.innerHTML.includes(scriptArray2[3])
      ) {
        e.remove()
      }

    })

  // Remove the div with the ID lass-script-load-div
  let divElement = parsedHtml.querySelector('#lass-script-load-div');
  if (divElement) {
    divElement.remove();
  }

  return `${parsedHtml.head.outerHTML}
  ${parsedHtml.body.outerHTML}`

};
