import React, { FunctionComponent } from "react";
import styles from "./card.module.scss";

enum cardTypes {
  stack = "stack",
  modular = "modular",
}

interface Props {
  type: cardTypes;
  month: string;
  date: string | number;
  year?: string | number;
  color?: string;
}

const CardDate: FunctionComponent<Props> = (props) => {
  if (props.type === cardTypes.modular) {
    return (
      <div className={styles.card_modular_date}>
        <p
          style={{ color: props.color }}
        >{`${props.month} ${props.date}, ${props.year}`}</p>
      </div>
    );
  } else {
    return (
      <div className={styles.card_stack_date}>
        <p>{props.month}</p>
        <span>{props.date}</span>
      </div>
    );
  }
};

CardDate.defaultProps = {
  type: cardTypes.stack,
  color: "#252525",
};

export default CardDate;
