import React, { Component } from "react";
import PropTypes from "prop-types";

import Icon from "../Icon";
import "./ProgressCircle.scss";

export default class ProgressCircle extends Component {
  constructor(props) {
    super(props);

    this.getValidatedValue = this.getValidatedValue.bind(this);
    this.getStrokeDashoffset = this.getStrokeDashoffset.bind(this);
  }

  getValidatedValue() {
    var value = Math.abs(this.props.value);

    if (value !== 0) {
      if (value % 100 === 0) {
        value = 100;
      } else {
        value = value % 100;
      }
    }

    return value;
  }

  getStrokeDashoffset(value) {
    return (((2 * 22 * 54) / 7) * (100 - this.getValidatedValue())) / 100;
  }

  render() {
    const value = this.getValidatedValue();
    let valueText = null;
    if (this.props.getProgressMessage) {
      valueText = this.props.getProgressMessage(value);
    }

    if (this.props.error) {
      return (
        <div className="Webcomponents WebcomponentsProgressCircle WebcomponentsProgressCircleError">
          <Icon
            isHidden={true}
            fileName="warning-18"
            name={"Warning"}
            focusable={false}
          />
        </div>
      );
    } else if (this.props.isLoader) {
      return (
        <div
          style={{
            ...this.props.svgStyle,
            borderColor: this.props.color,
            borderWidth: this.props.loaderWidth,
            borderStyle: "solid",
            borderLeftColor: "#f5f5f5",
          }}
          className="ls-spinning-loader"
        />
      );
    } else {
      return (
        <div className="Webcomponents WebcomponentsProgressCircle">
          <svg
            className="Webcomponents WebcomponentsProgressCircle"
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="120"
            viewBox="0 0 120 120"
            aria-labelledby="title"
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin="0"
            aria-valuetext={valueText}
            aria-valuemax="100"
            style={this.props.svgStyle}
          >
            <circle
              cx="60"
              cy="60"
              r="54"
              fill="none"
              stroke="#f5f5f5"
              strokeWidth={this.props.strokeWidth}
            />
            <circle
              cx="60"
              cy="60"
              r="54"
              fill="none"
              stroke={this.props.color}
              strokeWidth={this.props.strokeWidth}
              strokeDasharray="339.292"
              strokeDashoffset={this.getStrokeDashoffset()}
            />
            {this.props.isProgressTextEnabled && (
              <text
                x={
                  this.props.xAxis ? this.props.xAxis : value > 10 ? "30" : "36"
                }
                y={this.props.yAxis}
                style={this.props.textStyle}
                fill={this.props.color}
              >
                {value}%
              </text>
            )}
            {this.props.isCheckMarkVisible && (
              <svg
                aria-hidden="true"
                focusable="false"
                className="pe-icon--check-sm-18"
                viewBox="0 0 30 18"
                style={{ ...this.props.svgStyle, color: this.props.color }}
              >
                <use xlinkHref="#check-sm-18" />
              </svg>
            )}
          </svg>
        </div>
      );
    }
  }
}

ProgressCircle.propTypes = {
  /**
   * Percentage value of the progress bar.
   */
  value: PropTypes.number,

  /**
   * Color of the value circle.
   */
  color: PropTypes.string,

  /**
   * This callback is triggered on change to the value to get a text representation of the current progress
   * based on the given value.
   *
   * @param value: number
   */
  getProgressMessage: PropTypes.func,

  error: PropTypes.any,
  yAxis: PropTypes.string,
  xAxis: PropTypes.string,
  strokeWidth: PropTypes.number,
  svgStyle: PropTypes.object,
  textStyle: PropTypes.object,
  isProgressTextEnabled: PropTypes.bool,
  isCheckMarkVisible: PropTypes.bool,
  isLoader: PropTypes.bool,
  loaderWidth: PropTypes.string,
};

ProgressCircle.defaultProps = {
  value: 0,
  color: "#047a9c",
  strokeWidth: 12,
  isCheckMarkVisible: true,
  svgStyle: {},
  textStyle: {},
  yAxis: "75",
  isProgressTextEnabled: true,
  loaderWidth: "3px",
  isLoader: true,
};
