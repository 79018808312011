import React, { Component } from "react";
import './progressCircle.module.scss'
import styles from "./card.module.scss"

interface Props {
  avgCorrect: number;
  idBase: string;
  bottomLabel: string;
  outerLabel: string;
  customSizing: boolean; // true when more customization on the sizing is required
  circleDiameter: number; // everything will be scaled off of this number.  Max is 112px
}

class ProgressCircle extends Component<Props & React.HTMLAttributes<HTMLDivElement>, {}> {

  componentDidMount () {
    this.drawScoreCircle()
  }
  componentDidUpdate () {
    this.drawScoreCircle()
  }

  drawScoreCircle = () => {
    let canvas: HTMLCanvasElement = document.getElementById('summaryPercCircle' + this.props.idBase) as HTMLCanvasElement;
    let ctx: any = canvas.getContext('2d')
    // below is a gradient array to determine the color
    // 50% is the most red, 100% is the most green
    // numbers below are in red, green, blue
    let rgbArray: string[] =  [
      '198,71,78',
      '201,78,77',
      '202,81,76',
      '205,88,76',
      '207,93,74',
      '211,100,74',
      '213,104,74',
      '219,118,71',
      '221,123,71',
      '223,126,71',
      '225,131,70',
      '226,134,69',
      '229,141,68',
      '231,146,67',
      '233,149,66',
      '234,153,66',
      '239,163,64',
      '241,167,64',
      '243,172,64',
      '246,178,63',
      '248,183,62',
      '238,183,61',
      '235,183,61',
      '222,183,59',
      '212,184,58',
      '203,184,57',
      '191,184,56',
      '187,184,55',
      '179,184,54',
      '173,184,53',
      '163,184,53',
      '152,184,52',
      '148,184,51',
      '144,184,50',
      '139,184,49',
      '135,184,49',
      '126,169,52',
      '114,173,55',
      '100,177,59',
      '89,161,63',
      '79,156,66',
      '74,154,68',
      '70,151,69',
      '64,148,70',
      '58,145,72',
      '50,141,74',
      '42,138,77',
      '37,135,79',
      '27,130,82',
      '18,125,84',
      '9,121,87'
    ]
    const avgCorrect: number = isNaN(this.props.avgCorrect) ? 100 : this.props.avgCorrect
    let colorIndex: number = Math.round(avgCorrect)
    if (colorIndex < 50) {
      colorIndex = 50
    }
    colorIndex -= 50
    ctx.clearRect(0, 0, this.props.circleDiameter, this.props.circleDiameter)
    const lineWidth = 16 * (this.props.circleDiameter / 112) // proportional to the diamater, with max of 112
    const circleRadius: number = this.props.circleDiameter / 2
    ctx.lineWidth = lineWidth
    let finalAngle: number = (Math.PI * 1.5 + 2 * Math.PI * (avgCorrect / 100)) % (2 * Math.PI)
    if (avgCorrect === 100) {
      // done to prevent the circle from not drawing fully
      finalAngle = finalAngle - 0.000001
    }
    const circleColor = rgbArray[colorIndex]
    ctx.strokeStyle = `rgba(${circleColor},.15)`
    ctx.beginPath()
    ctx.arc(circleRadius, circleRadius, circleRadius - lineWidth / 2, Math.PI * 1.5, finalAngle)
    ctx.stroke()
    ctx.strokeStyle = `rgb(${circleColor})`
    ctx.beginPath()
    ctx.arc(circleRadius, circleRadius, circleRadius, Math.PI * 1.5, finalAngle)
    ctx.stroke()
  }

  render () {
    const { circleDiameter, idBase, avgCorrect, bottomLabel, outerLabel, customSizing } = this.props
    // below are all styles that vary based on the overal circle diameter
    const borderWidth = 8 * (circleDiameter / 112) // proportional to 112
    let avgCorrectDivStyles: any = {}
    let circleLeftPx: number = 0
    let cirleTopPx: number = 0
    if (customSizing) {
      avgCorrectDivStyles = {
        width: `${circleDiameter}px`,
        height: `${circleDiameter}px`,
        minWidth: `${circleDiameter}px`
      }
    } else {
      // used on the poll summary areas where there is a larger container
      circleLeftPx = 72
      cirleTopPx = 20
    }
    const outerCircleStyles: any = {
      width: `${circleDiameter}px`,
      height: `${circleDiameter}px`,
      border: `${borderWidth}px solid rgb(233,233,233)`,
      top: `${cirleTopPx}px`,
      left: `${circleLeftPx}px`
    }
    const innerCircleStyles: any = {
      width: `${circleDiameter - (2 * borderWidth)}px`,
      height: `${circleDiameter - (2 * borderWidth)}px`,
      border: `${borderWidth}px solid rgba(233,233,233,.2)`,
      top: `${cirleTopPx + borderWidth}px`,
      left: `${circleLeftPx + borderWidth}px`
    }
    const canvasStyles: any = {
      top: `${cirleTopPx}px`,
      left: `${circleLeftPx}px`
    }
    const containerDivStyles: any = {
      minHeight: `${circleDiameter}px`
    }
    const fontStyles: any = {
      fontSize: `${circleDiameter*0.216}px`
    }
    const avgCorrectShown: number = isNaN(avgCorrect) ? 100 : avgCorrect
    return (
      <div className={styles.avgCorrectDiv} style={avgCorrectDivStyles}>
        <div className={styles.outerContainer} style={containerDivStyles}>
        <div className={styles.scoreCircles} style={innerCircleStyles} />
        <div className={styles.scoreCircles} style={outerCircleStyles} />
        <canvas
          id={'summaryPercCircle' + idBase}
          width={`${circleDiameter}px`}
          height={`${circleDiameter}px`}
          style={canvasStyles}
          className={styles.scoreCircles}
        />
        <label className={styles.percCorrectLabel} style={fontStyles}>{avgCorrectShown + '%'}</label>
        {bottomLabel != null &&
          <label className={styles.avgScoreLabel}>{bottomLabel}</label>
        }
        </div>
        {
          outerLabel != null &&
        <label className={styles.avgPointsLabel}>{outerLabel}</label>
        }
        
      </div>
    )
  }

  static defaultProps ={
    customSizing: true,
    circleDiameter: 112
  }
  
}

export default ProgressCircle;