import React, { FunctionComponent, useState, useEffect } from "react";
import styles from "./confirmationModal.module.scss";
import Icon from "../Icon";
import Button from "../Button";
import FocusTrap from "focus-trap-react";
import BUTTON_SIZES from "../../const/BUTTON_SIZES";

type Props = {
  variant?: string; // Default, Confirmation
  id?: string;
  heading: string;
  body: string | HTMLElement;
  onClose?: () => Function;
  onProceed?: () => void;
  show: boolean;
  dismissible?: boolean;
  successButtonLable?: string;
  cancelButtonLable?: string;
  className?: string;
};

const ConfirmationModal: FunctionComponent<Props> = (props) => {

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 400px)").matches
  )

  useEffect(() => {
    const psarentClose = props.onClose();
    const close = (e) => {
      if (e.keyCode === 27) {
        psarentClose();
      }
    };

    window
    .matchMedia("(max-width: 400px)")
    .addEventListener('change', e => setMatches( e.matches ));

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);

  });

  return (
    props.show && (
      <>
        <div className="pe-lass-modal-container" id={props.id}>
          <div className={styles.pe_lass_modal_overlay}></div>
          <FocusTrap>
            <div
              className={`${styles.pe_lass_modal} ${props.className}`}
              role="dialog"
              aria-labelledby={`${props.id}-pe-lass-dialog-heading`}
              aria-describedby={`${props.id}-pe-lass-dialog-description`}
              aria-modal="true"
            >
              {props.dismissible && (
                <div className="close-title">
                  <Button
                    onClick={props.onClose()}
                    varient={"icon"}
                    size={BUTTON_SIZES.SMALL}
                    className={styles.pe_lass_icon_btn}
                    aria-label="Close dialog"
                  >
                    <Icon fileName={"remove-sm-18"} color={"#6a7070"} isHidden={true} />
                  </Button>
                </div>
              )}
              <h1
                id={`${props.id}-pe-lass-dialog-heading`}
                className={styles.pe_lass_modal_h1}
              >
                {props.heading}
              </h1>

              <div id={`${props.id}-pe-lass-dialog-description`}>
              <div
                className={styles.pe_lass_modal_body}
                id={`${props.id}-pe-lass-dialog-description`}
              >
                {typeof props.body === "string" ||
                  props.body instanceof String ? (
                  <p>{props.body}</p>
                ) : (
                  props.body
                )}
              </div>
              </div>
              <div className={styles.pe_lass_actions}>
                {props.cancelButtonLable && (
                  <Button
                    varient={"default"}
                    size={matches ? BUTTON_SIZES.SMALL : BUTTON_SIZES.LARGE}
                    onClick={props.onClose()}
                  >
                    {props.cancelButtonLable}
                  </Button>
                )}
                {props.successButtonLable && (
                  <Button
                    varient={"primary"}
                    size={matches ? BUTTON_SIZES.SMALL : BUTTON_SIZES.LARGE}
                    onClick={props.onProceed()}
                  >
                    {props.successButtonLable}
                  </Button>
                )}
              </div>
            </div>
          </FocusTrap>
        </div>
      </>
    )
  );
};

ConfirmationModal.defaultProps = {
  heading: "Modal title",
  body: "Modal body text goes here.",
  id: "modal-id-1",
  show: true,
  dismissible: true,
  variant: "Default",
};

export default ConfirmationModal;
