import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./../Icon";
import Calendar from "./../Calendar";

import "./datePicker.scss";
import Button from "../Button";

export default class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datepickerValue: this.props.datePickerValue || "",
    };
    this.applyDatePickerStyles = _applyDatePickerStyles.bind(this);
    this.datePickerOpen = _datePickerOpen.bind(this);
    this.calendarHandler = _calendarHandler.bind(this);
    this.changeHandler = _changeHandler.bind(this);
    this.parseDate = _parseDate.bind(this);
  }

  componentDidMount() {
    this.applyDatePickerStyles(this.props.inputState);
    document.addEventListener("click", this.clickListener);
  }

  componentWillReceiveProps(nextProps) {
    this.applyDatePickerStyles(nextProps.inputState);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.clickListener);
  }

  clickListener = (e) => {
    const currentElement = e.target;

    if (!this.container.contains(currentElement)) {
      this.setState({ displayOpen: false });
    }
  };

  handleKeys = (e) => {
    if (e.which === 27) {
      this.setState({ displayOpen: false });
      this.input.focus();
    }
    if (e.altKey && e.which === 40) {
      this.setState({
        displayOpen: true,
      });
    }
  };

  focusCheck = (e) => {
    if (
      document.activeElement === this.cal.children[0].children[0].children[2]
    ) {
      if (!e.shiftKey && e.which === 9) {
        this.setState({ displayOpen: false });
      }
    }
    if (
      document.activeElement ===
      this.cal.children[0].children[0].children[0].children[1]
    ) {
      if (e.shiftKey && e.which === 9) {
        this.setState({ displayOpen: false });
      }
    }
  };

  render() {
    const {
      inputStyle,
      labelStyleTmp,
      displayOpen,
      datepickerValue,
      dateObject,
      containerStyle,
      placeholder,
    } = this.state;
    const {
      className,
      inputState,
      id,
      labelText,
      infoMessage,
      errorMessage,
      dayNamesFull,
      monthNamesFull,
      weekStartDay,
      dayNamesShort,
      disablePast,
      minDate,
      maxDate,
      contrast,
    } = this.props;

    const em = inputState === "error" && errorMessage ? `errMsg-${id} ` : "";
    const ariaDescribedby = em + (infoMessage ? `infoMsg-${id}` : "");
    const mainContainerStyles = className
      ? `pe-datepicker-main ${className}`
      : `pe-datepicker-main`;
    const inputStyles = inputStyle
      ? `pe-datepicker-input-styles ${inputStyle}`
      : `pe-datepicker-input-styles`;

    return (
      <div
        className={mainContainerStyles}
        onKeyDown={this.handleKeys}
        onClick={this.datePickerOpen}
        ref={(dom) => (this.container = dom)}
      >
        <label className={labelStyleTmp} htmlFor={id}>
          {labelText}
        </label>

        <div className={containerStyle}>
          <input
            ref={(input) => (this.input = input)}
            type="text"
            id={id}
            placeholder={placeholder}
            value={datepickerValue}
            className={inputStyles}
            aria-describedby={ariaDescribedby}
            aria-invalid={inputState === "error"}
            disabled={inputState === "disabled"}
            readOnly={inputState === "readOnly"}
            onChange={this.changeHandler}
            autoComplete="off"
          />
          <Button className="pe-datepicker-icon" varient="icon">
            <Icon fileName="calendar-18" />
          </Button>
        </div>

        {infoMessage && (
          <span id={`infoMsg-${id}`} className="pe-input--info_message">
            {infoMessage}
          </span>
        )}

        {errorMessage && inputState === "error" && (
          <span id={`errMsg-${id}`} className="pe-input--error_message">
            <Icon fileName="warning-sm-18" isHidden={true} >Error</Icon> {errorMessage}
          </span>
        )}

        {displayOpen && inputState !== "readOnly" && (
          <div
            ref={(div) => (this.cal = div)}
            onKeyDown={this.focusCheck}
            className="pe-datepicker-calendar-container"
          >
            <Calendar
              contrast={contrast}
              disablePast={disablePast}
              minDate={minDate}
              maxDate={maxDate}
              newSelectedDt={dateObject}
              onSelect={this.calendarHandler}
              dayNamesFull={dayNamesFull}
              monthNamesFull={monthNamesFull}
              weekStartDay={weekStartDay}
              dayNamesShort={dayNamesShort}
            />
          </div>
        )}
      </div>
    );
  }
}

function _datePickerOpen() {
  const { inputState } = this.props;

  if (inputState === "" || inputState === "default" || inputState === "error") {
    this.setState({
      displayOpen: true,
    });
  }
}

function _changeHandler(e) {
  let dateObj = new Date();
  if (this.props.locale.toLowerCase() !== "en-us") {
    let dateArray = e.target === undefined ? e.split(".") : e.target.value.split(".");
    dateObj = new Date(`${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`);
  } else {
    dateObj = new Date(e.target === undefined ? e : e.target.value);
  }

  this.setState({
    datepickerValue:
      dateObj === "Invalid Date" ? this.parseDate(new Date()) : e.target === undefined ? e : e.target.value,
    dateObject: dateObj === "Invalid Date" ? new Date() : dateObj,
    displayOpen: false,
    labelStyleTmp: this.state.labelStyle,
  });
  this.props.changeHandler.call(
    this,
    dateObj === "Invalid Date" ? this.parseDate(new Date()) : e.target === undefined ? e : e.target.value,
    dateObj === "Invalid Date" ? new Date() : dateObj
  );
  this.input.focus();
}

function _parseDate(dateString) {
  if (dateString) {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    const event = new Date(dateString);
    const a = event.toLocaleDateString(this.props.locale, options);

    return a;
  }
}

function _calendarHandler(date) {
  let dateString = this.parseDate(date.selectedDt);

  this.setState({
    datepickerValue: dateString,
    dateObject: date.selectedDt,
    displayOpen: false,
    labelStyleTmp: this.state.labelStyle,
  });

  this.changeHandler(dateString);
}

function _applyDatePickerStyles(inputState) {
  let { labelStyle, inputStyle, labelFocusStyle, containerStyle } = this.state;

  switch (inputState) {
    case "error":
      labelStyle = "pe-textLabelInput__label--label_error";
      labelFocusStyle = "pe-textLabelInput__label--label_error";
      inputStyle = "pe-textInput--basic_error";
      containerStyle = "pe-datepicker-container";
      break;
    case "disabled":
      labelStyle = "pe-textLabelInput__label";
      labelFocusStyle = "pe-textLabelInput__label";
      containerStyle = "pe-datepicker-container";
      inputStyle = "pe-textInput--basic";
      break;
    case "readOnly":
      labelStyle = "pe-textLabelInput__label";
      labelFocusStyle = "pe-textLabelInput__label";
      inputStyle = "pe-textInput--input_readonly";
      containerStyle = "pe-datepicker-container";
      break;
    default:
      labelStyle = "pe-textLabelInput__label";
      labelFocusStyle = "pe-textLabelInput__label--label_focus";
      inputStyle = "pe-textInput--basic";
      containerStyle = "pe-datepicker-container";
  }

  this.setState({
    labelStyle,
    labelStyleTmp: labelStyle,
    inputStyle,
    labelFocusStyle,
    containerStyle,
  });
}

DatePicker.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string,
  locale: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  infoMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  inputState: PropTypes.string,
  className: PropTypes.string,
  disablePast: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  dayNamesFull: PropTypes.arrayOf(PropTypes.string),
  monthNamesFull: PropTypes.arrayOf(PropTypes.string),
  weekStartDay: PropTypes.number,
  dayNamesShort: PropTypes.arrayOf(PropTypes.string),
  datepickerValue: PropTypes.string,
  contrast: PropTypes.bool,
};

DatePicker.defaultProps = {
  locale: "en-us",
  inputState: "",
  labelText: "Select date",
  id: Date.now().toString(),
  contrast: false,
};
