import React, { FunctionComponent } from "react";
import styles from "./table.module.scss";
import Icon from "./../Icon";
import CheckBox from "./../CheckBox";
import DropDownList from "./../DropDownList";
import { generateId } from "../_util/common.js";

type Column = {
  title: string;
  dataIndex: string;
  key: number;
  sortable?: boolean;
  width?: string;
  displayValue?: any;
};

type Row = {
  key: number;
};

type Filter = {
  title: string;
  value: string;
  logic: (record: Row) => Row;
};

enum Theme {
  default = "default",
  wsol = "wsol",
}

type Props = {
  columns: Column[];
  sortingData?: {
    dataIndex: string;
    direction: string;
    onSort: (dataIndex: string) => void;
  };
  selectionData?: {
    selectedAll: boolean;
    mixedState: boolean;
    onSelect: (status: string) => void;
    filter?: Filter[];
    selectedFilter?: string;
    hideSelectLabel?: boolean;
    columnWidth?: string;
  };
  theme: Theme;
  uuid: string;
};

const TableHeader: FunctionComponent<Props> = (props) => {
  
  function renderSortableCell(column: Column) {
    let sort_type:string = "Unsorted";
    if(props.sortingData.dataIndex === column.dataIndex && props.sortingData.direction === "asc"){
      sort_type = "Sorted ascending";
    }else if(props.sortingData.dataIndex === column.dataIndex && props.sortingData.direction !== "asc"){
      sort_type = "Sorted descending";
    }  
    return (
      <button
        className={`${styles[`table_${props.theme}_sortable_cell`]} ${
          props.sortingData.dataIndex === column.dataIndex
            ? styles[`table_${props.theme}_sortable_cell_active`]
            : ""
        }`}
        onClick={() => {
          props.sortingData.onSort(column.dataIndex);
        }}
        title={sort_type}
      >
        {column.title}
        <Icon
          name={sort_type}
          fileName={
            props.sortingData.dataIndex === column.dataIndex
              ? props.sortingData.direction === "asc"
                ? "descending-18"
                : "ascending-18"
              : "sortable-18"
          }
          className={styles[`table_${props.theme}_header_icon`]}
        />
      </button>
    );
  }

  function renderCheckboxFilter() {
    const options = props.selectionData.filter.map((filter) => {
      return {
        display: filter.title,
        value: filter.value,
      };
    });
    return (
      <DropDownList
        id={`dropdown_${generateId()}`}
        triggerType="checkbox"
        onGetSelectedOption={props.selectionData.onSelect}
        options={options}
        selectedOption={props.selectionData.selectedFilter}
      />
    );
  }

  const renderDisplayValue = (column: Column) => {
    if (column.displayValue) {
      return column.displayValue;
    } else {
      return column.title;
    }
  };

  return (
    <thead>
      <tr className={styles[`table_${props.theme}_header`]}>
        {props.selectionData ? (
          <th
            style={
              props.selectionData.columnWidth !== null
                ? {
                    width: `${props.selectionData.columnWidth}`,
                  }
                : {}
            }
            className={`${styles[`table_${props.theme}_header_cell`]}`}
          >
            {props.selectionData.filter ? (
              renderCheckboxFilter()
            ) : (
              <CheckBox
                checked={props.selectionData.selectedAll}
                groupName="CheckBox-Group-One"
                id={`checkbox_${props.uuid}_header`}
                onCheckboxChange={() => {
                  props.selectionData.onSelect(
                    !props.selectionData.selectedAll === false ? "none" : "all"
                  );
                }}
                size="18"
                value={""}
                mixedState={props.selectionData.mixedState}
                ariaLabel="select all checkbox"
              >
                {!props.selectionData.hideSelectLabel && `Select all`}
              </CheckBox>
            )}
          </th>
        ) : null}
        {props.columns.map((column, index) => {
          return (
            <th
              className={`${styles[`table_${props.theme}_header_cell`]} ${
                column.sortable
                  ? styles[`table_${props.theme}_header_cell_sortable`]
                  : ""
              }`}
              key={column.key}
              role={column.sortable ? "status" : "columnheader"}
              style={column.width && { width: column.width }}
            >
              {column.sortable
                ? renderSortableCell(column)
                : renderDisplayValue(column)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
export default TableHeader;
