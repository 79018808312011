import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

export const Confirmation = ({ okLabel, cancelLabel, title, confirmation, modalActive, proceed,
    cancel, enableEscape, showConfirmation }) => {
  const onProceed = () => {
    proceed()
    showConfirmation(false)
  }

  const onCancel = () => {
    if (cancel) {
      cancel()
    }
    showConfirmation(false)
  }

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  }

  // The react-mmodal plug-in doesn't support aria-labeledby or aria-describedby,
  // so we combine the title and description into a single aria-label
  const fullAriaLabel = title + ' ' + confirmation

  return (
    <div>
      <Modal
        isOpen={modalActive}
        style={customStyles}
        className='pe-proto modal delete-question-template'
        role='dialog'
        contentLabel={fullAriaLabel}
      >
        <section id='cancelQ_modal'>
          <div className='pe-template__static-newui small'>
            <div id='modal-title-cancelq' className='modal-title'>
              <h3 className='pe-title'>{title}</h3>
            </div>
            <div id='modal-content-cancelq' className='modal-content row'>
              <p>{confirmation}</p>
            </div>
            <div id='CancelQ' className='button-actions'>
              <button autoFocus className='pe-btn--btn_xlarge grey' onClick={onCancel}>{cancelLabel}</button>
              <button className='pe-btn--btn_xlarge pe-btn__primary' onClick={onProceed}>{okLabel}</button>
            </div>
          </div>
        </section>
      </Modal>
    </div>
  )
}

Confirmation.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string.isRequired,
  modalActive: PropTypes.bool.isRequired,
  proceed: PropTypes.func,     // called when ok button is clicked.
  cancel: PropTypes.func,      // called when cancel button is clicked.
  enableEscape: PropTypes.bool,
  showConfirmation: PropTypes.func.isRequired
}

export default Confirmation
