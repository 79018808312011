import React, { FunctionComponent, useEffect } from "react";
import styles from "./directory.module.scss";
import Icon from "./../Icon";
import CheckBox from "./../CheckBox";
import DropDownList from "./../DropDownList";
import { generateId } from "../_util/common.js";
import Tootip from "../ToolTip/ToolTip.tsx";

type Column = {
  title: string;
  dataIndex: string;
  key: number;
  sortable?: boolean;
  width?: string;
  displayValue?: any;
  tooltip?: boolean;
  tooltipText?: string;
  readTooltip?: boolean;
};

type Row = {
  key: number;
};

type Filter = {
  title: string;
  value: string;
  logic: (record: Row) => Row;
};

enum Theme {
  default = "default",
  wsol = "wsol",
}

type Props = {
  columns: Column[];
  columnOneTitle?: string;
  sortingData?: {
    dataIndex: string;
    direction: string;
    onSort: (dataIndex: string) => void;
  };
  selectionData?: {
    selectedAll: boolean;
    mixedState: boolean;
    onSelect: (status: string) => void;
    filter?: Filter[];
    selectedFilter?: string;
    hideSelectLabel?: boolean;
    columnWidth?: string;
  };
  theme: Theme;
};

const DirectoryHeader: FunctionComponent<Props> = (props) => {

  useEffect(() => {
    let arrayItemspart = document.getElementsByClassName(`${styles[`table_${props.theme}_header_cell`]}`);
   
     for(let i=0 ; i<arrayItemspart.length;i++){
       if(arrayItemspart[i].getAttribute("role") === ""){
         arrayItemspart[i].removeAttribute("role")
       }
     }
   }, []);

  function getSortStatus(column) {
    let sort_type: string = "Unsorted";

    if (props.sortingData.dataIndex === column.dataIndex && props.sortingData.direction === "asc") {
      sort_type = "Sorted ascending";
    } else if (props.sortingData.dataIndex === column.dataIndex && props.sortingData.direction !== "asc") {
      sort_type = "Sorted descending";
    }

    return sort_type;
  }

  function renderSortableHeaderButton(column, sortStatus) {
    return (
      <button
        className={`${styles[`table_${props.theme}_sortable_cell`]} ${props.sortingData.dataIndex === column.dataIndex
          ? styles[`table_${props.theme}_sortable_cell_active`]
          : ""
          }`}
        onClick={() => {
          props.sortingData.onSort(column.dataIndex);
        }}
        aria-label={!column.tooltip || !column.readTooltip ? `${column.title}, ${sortStatus}` : sortStatus}
      >
        {column.title}
        <Icon
          isHidden={true}
          name={sortStatus}
          title={
            props.sortingData.dataIndex === column.dataIndex
              ? props.sortingData.direction === "asc"
                ? "Ascending"
                : "Descending"
              : "Sortable"
          }
          fileName={
            props.sortingData.dataIndex === column.dataIndex
              ? props.sortingData.direction === "asc"
                ? "descending-18"
                : "ascending-18"
              : "sortable-18"
          }
          className={styles[`table_${props.theme}_header_icon`]}
        />
      </button>
    )
  }

  function renderSortableCell(column: Column) {
    let sortStatus = getSortStatus(column);

    return (
      <>
        {column.tooltip ? (
          <Tootip
            tooltipId={`tooltipId-${column.title}`}
            placement="bottom"
            message={column.tooltip && column.tooltipText ? column.tooltipText : sortStatus}
            ariaHidden={!column.readTooltip} >
            {renderSortableHeaderButton(column, sortStatus)}
          </Tootip>
        ) : (
          renderSortableHeaderButton(column, sortStatus)
        )}
      </>
    );
  }

  function renderCheckboxFilter() {
    const options = props.selectionData.filter.map((filter) => {
      return {
        display: filter.title,
        value: filter.value,
      };
    });
    return (
      <DropDownList
        id={`dropdown_${generateId()}`}
        triggerType="checkbox"
        onGetSelectedOption={props.selectionData.onSelect}
        options={options}
        selectedOption={props.selectionData.selectedFilter}
      />
    );
  }

  const renderDisplayValue = (column: Column) => {
    if (column.displayValue) {
      return column.displayValue;
    } else {
      return column.title ? column.title : column.key
    }
  };

  return (
    <thead>
      <tr className={styles[`table_${props.theme}_header`]}>
        {
          <th className={`${styles[`table_${props.theme}_header_cell`]} visually-hidden`}>
               {props.columnOneTitle}
          </th>
        }
        {props.selectionData ? (
          <td
            style={
              props.selectionData.columnWidth !== null
                ? {
                    width: `${props.selectionData.columnWidth}`,
                  }
                : {}
            }
            className={`${styles[`table_${props.theme}_header_cell`]}`}
          >
            {props.selectionData.filter ? (
              renderCheckboxFilter()
            ) : (
              <CheckBox
                checked={props.selectionData.selectedAll}
                groupName="CheckBox-Group-One"
                id={`checkbox_header`}
                onCheckboxChange={() => {
                  props.selectionData.onSelect(
                    !props.selectionData.selectedAll === false ? "none" : "all"
                  );
                }}
                size="18"
                value={""}
                mixedState={props.selectionData.mixedState}
                ariaLabel="Select all"
              >
                {!props.selectionData.hideSelectLabel && `Select all`}
              </CheckBox>
            )}
          </td>
        ) : null}
        {props.columns.map((column, index) => {
          return (
            <th
              className={`${styles[`table_${props.theme}_header_cell`]} ${
                column.sortable
                  ? styles[`table_${props.theme}_header_cell_sortable`]
                  : ""
              } ${
                column.title
                  ? ""
                  : "visually-hidden"
              }`}
              key={column.key}
              role={column.sortable ? "status" : ""}
              style={column.width && { width: column.width }}
            >
              {column.sortable
                ? renderSortableCell(column)
                : renderDisplayValue(column)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
export default DirectoryHeader;
