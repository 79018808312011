import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import FileTitle from './FileTitle'
import FileUploadProgressCircle from './FileUploadProgressCircle'
import { CLOSE, COMPLETED, PROCESSING, UPLOADING } from '../const/uploader'
import Icon from '../../../Icon'
import styles from '../file-upload.module.scss'
import UploaderContext from '../containers/UploaderContext'

export const SingleFileUploadStatus = ({ deleteFilesCallback, hideRemoveButton }) => {
  const context = useContext(UploaderContext)
  let file = context.state.files[0]
  let statusText = file.status === COMPLETED ? 'delete' : 'cancel'
  let htmlContent = ''
  let svgStyle = { 'width': '70px', 'height': '70px' }
  const handleDelete = () => {
    deleteFilesCallback(0)
    context.setSingleFileUpload(CLOSE)
  }

  switch (file.status) {
    case UPLOADING:
      htmlContent = <FileUploadProgressCircle
        file={file}
        styles={{ yAxis : '70',
          strokeWidth: 8,
          svgStyle: svgStyle,
          textStyle: { 'fontSize': '18px' },
          xAxis: '45' }}
        isCircleHidden={false}
        isCheckMarkVisible={false}
      />
      break
    case PROCESSING:
      htmlContent = <FileUploadProgressCircle
        styles={{ strokeWidth: 8,
          isProgressTextEnabled: false,
          svgStyle: svgStyle }}
        file={file}
        isCheckMarkVisible={false}
        isCircleHidden={false}
      />
      break
    case COMPLETED:
      htmlContent = <FileUploadProgressCircle file={file} isCheckMarkVisible={false} isCircleHidden />
      break
    default:
      break
  }
  return (
    <div className={styles.singleFileStatus}>
      <div className={styles.progressCircleStatus}>
        {htmlContent}
      </div>
      <p className={styles.statusText}>{file.status.charAt(0) + file.status.substring(1).toLowerCase()}</p>
      <div className={styles.statusFileInfo}>
        <FileTitle FileNameStyle={styles.fileNameStatus} fileName={file.fileName} fileSize={file.fileSize}
          uploadProgress={file.uploadProgress} fileSizeTextStyle={styles.statusFileSizeText}
          FileNameTextStyle={styles.statusFileNameText} uploadStatus={file.status}>
            {hideRemoveButton === true && file.status !== COMPLETED ? null:
              <button
              id={`${statusText}-${file.fileId}`}
              className={`${styles.iconBtn} ${styles.heightPx} ${styles.closeIcon}`}
              aria-label={`${statusText} this file ${file.fileName}`}
              onClick={() => handleDelete()}
              >
            <Icon fileName='delete-18' name={`${statusText} the file`} title={`${statusText} the file`} />
          </button>}
        </FileTitle>
      </div>
    </div>
  )
}

SingleFileUploadStatus.propTypes = {
  deleteFilesCallback: PropTypes.func.isRequired
}

SingleFileUploadStatus.defaultProps = {
  file: {
    status: '',
    fileSize: 0,
    fileName: '',
    fileId: '',
    uploadProgress: 0
  }
}

export default SingleFileUploadStatus
