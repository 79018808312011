/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './alert.module.scss';
import Icon from '../Icon';

type Props = {
  variant: string; // Default, Success , Danger
  id: string;
  heading?: string;
  body: string;
  dismissible: boolean;
  onClose: () => void;
  show: boolean;
  className?: string;
};

const Alert: FunctionComponent<Props> = (props) => {
  const alertVariant = cx(
    `${styles.pe_lass_alert_box}`,
    props.className,
    props.variant === "Default" && `${styles.pe_lass_alert_box_default}`,
    props.variant === "Success" && `${styles.pe_lass_alert_box_success}`,
    props.variant === "Danger" && `${styles.pe_lass_alert_box_error}`,
    props.heading && `${styles.pe_lass_alert_box_heading_variant}`,
    !props.heading && `${styles.pe_lass_alert_box_headingless_variant}`
  );

  const alertRef = useRef(null);

  useEffect(() => {
    if (props.focusTrap) {
      const existingAlert = document.querySelector('.lass-alert:last-of-type');
      if (existingAlert) {
        existingAlert.focus();
      } else {
        alertRef.current.focus();
      }
    }
  }, []);

  const handleClose = (e) => {
    props.onClose(false, e);
  };

  const populateIcon = () => {
    switch (props.variant) {
      case "Default":
        return (
          <Icon
            className={styles.pe_lass_alert_default_icon}
            fileName={"info-24"}
            isHidden={true}
            color={"#047a9c"}
          />
        );
      case "Success":
        return (
          <Icon
            className={styles.pe_lass_alert_success_icon}
            fileName={"check-lg-24"}
            isHidden={true}
            color={"#038238"}
          />
        );
      case "Danger":
        return (
          <Icon
            className={styles.pe_lass_alert_danger_icon}
            fileName={"warning-24"}
            isHidden={true}
            color={"#ca4644"}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    props.show && (
      <div
        className={`lass-alert ${alertVariant}`}
        role="alert"
        id={props.id !== undefined ? props.id : null}
        ref={alertRef}
        aria-live={props.focusTrap ? "assertive" : undefined}
        aria-atomic={props.focusTrap ? "true" : undefined}
        tabIndex={props.focusTrap ? "-1" : undefined}
      >
        {props.dismissible && (
          <div className="close-title">
            <button
              onClick={handleClose}
              type="button"
              className={styles.pe_lass_icon_btn}
              aria-label="Close alert"
            >
              <Icon fileName={"remove-sm-18"} color={"#6a7070"} title={"close"} />
            </button>
          </div>
        )}
        <div className={styles.pe_lass_alert_content}>
          {populateIcon()}
          <div className={styles.pe_lass_alert_text_content}>
            {props.heading && (
              <h1 className={styles.alert_title}>{props.heading}</h1>
            )}
            <p className={styles.alert_text}>{props.body}</p>
          </div>
        </div>
      </div>
    )
  );
};

Alert.defaultProps = {
  dismissible: true,
  variant: "Default",
  show: true,
  focusTrap: false
};

export default Alert;
