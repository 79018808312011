import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import UploadTitleBar from "./UploaderTitleBar";
import {
  CLOSE,
  COMPLETED,
  PROCESSING,
  UPLOADING,
  DONE,
} from "../const/uploader";
import styles from "../file-upload.module.scss";
import UploaderContext from "../containers/UploaderContext";

export const FileUploadModal = ({
  children,
  uploadLabel,
  cancelFiles,
  doneUpload,
  applyOverlay,
  keepOverlay,
  closeModal,
  minimizeIconEnabled,
}) => {
  const context = useContext(UploaderContext);
  const [isApplyOverlay, setApplyOverlay] = useState(applyOverlay);
  /**
   * terminate the process of in progress files and close the modal
   * the Cancel button is only executed when modal is enabled
   */
  const handleCancel = (e) => {
    let filesToCancel = context.state.files.map((file, index) => {
      if (file.status === UPLOADING || file.status === PROCESSING) {
        return index;
      }
    });
    let remainingFileCount = context.state.files.filter(
      (file) => file.status === COMPLETED
    ).length;
    cancelFiles(filesToCancel);
    if (remainingFileCount === 0) context.setUploadingView(CLOSE);
    e.preventDefault();
    context.resetFiles();
    context.setUploader(CLOSE);
    setApplyOverlay(false);
  };

  /**
   * Close the modal
   */
  const handleClose = (e) => {
    context.resetFiles();
    context.setUploader(CLOSE);
    if (closeModal) closeModal(CLOSE);
    e.preventDefault();
    setApplyOverlay(false);
  };

  /**
   * close the modal once the done is enabled
   */
  const handleDone = () => {
    context.resetFiles();
    context.setUploader(CLOSE);
    if (doneUpload) doneUpload(DONE);
    if (!keepOverlay) {
      setApplyOverlay(false);
    }
  };
  return (
    <div className={styles.modal}>
      {isApplyOverlay && <div className={styles.overlay} />}
      <UploadTitleBar
        uploadLabel={uploadLabel}
        setUploader={context.setUploader}
        id={context.state.id}
        handleClose={(e) => handleClose(e)}
        setUploadMinimizerView={context.setUploadMinimizerView}
        uploadingViewStatus={context.state.uploadingViewOpened}
        minimizeIconEnabled={minimizeIconEnabled}
      />
      {children}
      {context.state.uploadingViewOpened && (
        <div className={styles.doneOrCancel}>
          <button
            className={styles.fileInputButtonStyle}
            aria-label="Select cancel to cancel files with uploading in progress, Cancel"
            onClick={(e) => handleCancel(e)}
            id={`cancel-${context.state.id}`}
          >
            Cancel
          </button>
          <button
            disabled={context.state.isDoneEnabled}
            style={{ margin: "0px 0px 0px 12px" }}
            className={
              context.state.isDoneEnabled
                ? `${styles.fileInputButtonDisabledStyle} ${styles.fileDoneButtonStyle}`
                : styles.fileDoneButtonStyle
            }
            aria-label="Select done to close the file uploader if file uploading is completed, Done"
            onClick={(e) => handleDone()}
            id={`done-${context.state.id}`}
          >
            Done
          </button>
        </div>
      )}
    </div>
  );
};

FileUploadModal.propTypes = {
  children: PropTypes.object.isRequired,
  uploadLabel: PropTypes.string.isRequired,
  doneUpload: PropTypes.func,
  closeModal: PropTypes.func,
  cancelFiles: PropTypes.func.isRequired,
  minimizeIconEnabled: PropTypes.bool.isRequired,
  applyOverlay: PropTypes.bool,
  keepOverlay: PropTypes.bool,
};

export default FileUploadModal;
