import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CLOSE, OPEN, UPLOADING, PROCESSING, COMPLETED } from '../const/uploader'
const UploaderContext = React.createContext()
class UploaderProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      uploaderStatus: OPEN,
      uploadingViewStatus: CLOSE,
      singleFileUploadStatus: CLOSE,
      uploadMinimizerViewStatus: CLOSE,
      errorMessage: '',
      id: '',
      files: []
    }
    this.setSingleFileUpload = this.setSingleFileUpload.bind(this)
    this.updateFileData = this.updateFileData.bind(this)
    this.setUploader = this.setUploader.bind(this)
    this.setUploadingView = this.setUploadingView.bind(this)
    this.setErrorMessage = this.setErrorMessage.bind(this)
    this.resetFiles = this.resetFiles.bind(this)
    this.setUploadMinimizerView = this.setUploadMinimizerView.bind(this)
    this.setUploaderId = this.setUploaderId.bind(this)
  }
  setUploaderId (id) {
    this.setState({ id: id })
  }
  resetFiles () {
    this.updateFileData([])
    this.setErrorMessage('')
  }

  setUploadMinimizerView (uploadMinimizerViewStatus) {
    this.setState({ uploadMinimizerViewStatus: uploadMinimizerViewStatus })
  }

  setSingleFileUpload (status) {
    this.setState({ singleFileUploadStatus: status })
  }

  updateFileData (files) {
    this.setState({ files: files })
  }

  setUploader (uploaderStatus) {
    this.setState({ uploaderStatus: uploaderStatus })
  }

  setUploadingView (uploadingViewStatus) {
    this.setState({ uploadingViewStatus: uploadingViewStatus })
  }

  setErrorMessage (error) {
    this.setState({ errorMessage: error })
  }

  render () {
    let inProgressFileCount = this.state.files.filter(file =>
      file.status === UPLOADING || file.status === PROCESSING).length
    let doneFileCount = this.state.files.filter(file =>
      file.status === COMPLETED).length
    let isDoneEnabled = !(this.state.files.filter(file =>
      file.status === COMPLETED).length === this.state.files.length)
    let uploadingViewOpened = this.state.uploadingViewStatus === OPEN
    return (
      <UploaderContext.Provider value={{
        state: {
          ...this.state,
          inProgressFileCount: inProgressFileCount,
          doneFileCount: doneFileCount,
          isDoneEnabled: isDoneEnabled,
          uploadingViewOpened: uploadingViewOpened
        },
        updateFileData: this.updateFileData,
        setUploader: this.setUploader,
        setUploadingView: this.setUploadingView,
        setSingleFileUpload: this.setSingleFileUpload,
        setErrorMessage: this.setErrorMessage,
        resetFiles: this.resetFiles,
        setUploadMinimizerView: this.setUploadMinimizerView,
        setUploaderId: this.setUploaderId
      }}>
        {this.props.children}
      </UploaderContext.Provider>
    )
  }
}

UploaderProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default UploaderContext

export { UploaderProvider }
