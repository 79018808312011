import React, { useState } from "react";
import Icon from '../Icon';
import Button from './../Button/Button.tsx';
import styles from "./sideDrawer.module.scss";

const SideDrawer = ({ position, type, collapsible, children, }) => {

    const [collapsed, setcollapsed] = useState(false)

    return (
        <div className={`${styles.sideDrawer} ${styles[position]} ${styles[type]} ${collapsed ? styles.collapsed : styles.expanded}`}>
            {children}
            {collapsible &&
                <div className={styles.sideDrawerBottom}>
                    <div className={`${styles.sideDrawerFooter} ${styles[position]}`}>
                        <Button
                            type="button"
                            varient="icon"
                            className={`pe-icon--btn ${styles.sideDrawerToggle}`}
                            onClick={() => setcollapsed(!collapsed)} >
                            <Icon
                                fileName={`${position}-${collapsed ? 'maximize' : 'minimize'}-18`}
                                name={collapsed ? 'maximize' : 'minimize'}
                                title={collapsed ? 'maximize' : 'minimize'}
                            />
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
}

SideDrawer.defaultProps = {
    position: 'left',
    type: 'push',
    collapsible: 'false',
    collapsed: false
};

export default SideDrawer;