import Icon from '../../Icon'
import React from 'react'
import PropType from 'prop-types'

const PaginationButton = ({ ariaLabel, isDisabled, onClick, iconFileName,
  iconName, iconTitle, buttonId, svgClass, className }) => {
  return <button
    id={buttonId}
    type='button'
    className='ls-btn icon-btn-18'
    aria-label={ariaLabel}
    disabled={isDisabled}
    onClick={onClick}
>
    <span className={`${svgClass} ${className}`}>
      <Icon fileName={iconFileName} name={iconName} title={iconTitle} />
    </span>

  </button>
}
PaginationButton.propTypes = {
  ariaLabel:PropType.string,
  isDisabled:PropType.bool,
  onClick:PropType.func,
  iconFileName:PropType.string,
  iconName:PropType.string,
  iconTitle:PropType.string,
  buttonId:PropType.string,
  svgClass:PropType.string,
}

PaginationButton.defaultProps = {
  svgClass:'',
  consumer:"Default"
}

export default PaginationButton
