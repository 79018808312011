/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2018 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import React, { Component } from 'react'
import { func, number, string } from 'prop-types'
import PaginationButton from './common/PaginationButton'
import './Pagination.scss'
const PREVIOUS = 'previousPage'
const NEXT = 'nextPage'

class PaginationGroup extends Component {
  constructor (props) {
    super(props)

    this.state = {
      startingRow:this.props.startingRow ? this.props.startingRow : 1,
      endingRow:this.props.endingRow ? this.props.endingRow : this.props.rowsPerGroup
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.startingRow !== this.props.startingRow) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        startingRow: this.props.startingRow
      }))
    }
    if (prevProps.endingRow !== this.props.endingRow) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        endingRow: this.props.endingRow
      }))
    }
  }

  setNextGroup = () => {
    if (this.props.rowsPerGroup !== undefined) {
      var gap = this.props.numberOfRecords - this.state.endingRow
      if (gap < this.props.rowsPerGroup) {
        this.setState(prevState => ({
          startingRow:prevState.endingRow + 1,
          endingRow: prevState.endingRow + (this.props.numberOfRecords - prevState.endingRow)
        }), this.props.handlePage(this.state.endingRow + 1,
          this.state.endingRow + (this.props.numberOfRecords - this.state.endingRow), NEXT))
      } else {
        this.setState(prevState => ({
          startingRow:prevState.endingRow + 1,
          endingRow: prevState.endingRow + this.props.rowsPerGroup
        }), this.props.handlePage(this.state.endingRow + 1, this.state.endingRow + this.props.rowsPerGroup, NEXT))
      }
    } else {
      this.props.nextButtonOnClick()
    }
  }

  setPreviousGroup = () => {
    if (this.props.rowsPerGroup !== undefined) {
      this.setState(prevState => ({
        endingRow:  prevState.startingRow - 1,
        startingRow:prevState.startingRow - (this.props.rowsPerGroup)
      }), this.props.handlePage(this.state.startingRow - (this.props.rowsPerGroup), this.state.startingRow - 1, PREVIOUS))
    } else {
      this.props.previousButtonOnClick(this.props.startingRow, this.props.endingRow)
    }
  }

  render () {
    let { numberOfRecords } = this.props
    const middleContent = ' of '
    let disabledBackButton = this.state.startingRow === 1
    let disabledNextButton = this.state.endingRow === numberOfRecords
    let backAriaLabel = this.props.backAriaLabel
    let nextAriaLabel = this.props.nextAriaLabel
    if (disabledBackButton) {
      backAriaLabel += ', disabled currently'
    }
    if (disabledNextButton) {
      nextAriaLabel += ', disabled currently'
    }

    return (
      <div className='ls-pagination' role='navigation' aria-label='pagination'>
        <PaginationButton ariaLabel={backAriaLabel}
          isDisabled={this.state.startingRow === 1}
          onClick={this.setPreviousGroup} iconFileName='chevron-next-sm-18' iconName='back-page'
          iconTitle='Prior Page' buttonId='prevPage' svgClass='back-svg' />

        <span className='ls-pagination-compactGroup' aria-live='polite' role='alert'>
          <span> {this.state.startingRow}
            <span> - </span>
            {this.state.endingRow}</span>
          <span>{middleContent}</span>
          <span>{this.props.numberOfRecords}</span>
        </span>
        <PaginationButton ariaLabel={nextAriaLabel}
          isDisabled={this.state.endingRow === this.props.numberOfRecords}
          onClick={this.setNextGroup} iconFileName='chevron-next-sm-18' iconName='next-page'
          iconTitle='Next Page' buttonId='nexPage' />

      </div>
    )
  }
}

PaginationGroup.propTypes = {
  numberOfRecords: number,
  handlePage: func,
  pageLabel: string,
  rowsPerGroup: number,
  startingRow:number,
  endingRow:number,
  nextButtonOnClick:func,
  previousButtonOnClick:func,
  backAriaLabel: string,
  nextAriaLabel: string
}
PaginationGroup.defaultProps = {
  handlePage: () => {/**/}
}

export default PaginationGroup
