export const generateId = () => {
  return Math.random().toString(36).substring(7);
};

export const loadScript = (scriptName, scriptUrl, callbackFn) => {
  const scriptId = `script-${scriptName}`;

  if (document.getElementById(scriptId)) {
    callbackFn();
    return;
  }

  const script = document.createElement("script");
  script.id = scriptId;
  script.src = scriptUrl;
  script.onload = callbackFn;
  document.head.appendChild(script);

  script.onerror = () => {
    /* eslint-disable no-console */
    console.log(`Error loading file - ${scriptName}`);

    if (script.parentElement) {
      script.parentElement.removeChild(script);
    }
  };
};
