import React, { FunctionComponent } from "react";
import styles from "./ProgressBar_2.module.scss";

type Props = {
  percentage: number;
};

const ProgressBar: FunctionComponent<Props> = (props) => {
  let tot = props.percentage;
  if(props.percentage && props.percentage > 100){
    tot = 100;
  }else if(props.percentage  < 0){
    tot = 0
  }
  const percentage = tot
  return (
    <div className={styles.ls_progress}>
      <div
        className={styles.ls_progress_bar}
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <span
          className={styles.ls_progress_fill}
          style={{ transform: `translateX(-${100 - percentage}%)` }}
        ></span>
      </div>
      <span className={styles.ls_progress_percentage}>
        {percentage}% complete
      </span>
    </div>
  );
};

export default ProgressBar;
