// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bread_crumbs_breadcrumbs__\\+2xMv{display:inline-flex}\n", "",{"version":3,"sources":["webpack://./src/components/BreadCrumbs/bread_crumbs.module.scss"],"names":[],"mappings":"AAAA,kCACI,mBAAoB","sourcesContent":[".breadcrumbs {\n    display: inline-flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": "bread_crumbs_breadcrumbs__+2xMv"
};
export default ___CSS_LOADER_EXPORT___;
