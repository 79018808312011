import React from 'react';
import MessageBox from './MessageBox'
import ReactDOM from "react-dom";


var Success = function (body, heading, closeTime) {
    return ReactDOM.render(
        <MessageBox variant="Success" body={body} heading={heading} closeTime={closeTime}></MessageBox>,
        document.createElement("alert_box_div")
    );
}
var Default = function (body, heading, closeTime) {
    return ReactDOM.render(
        <MessageBox variant="Default" body={body} heading={heading} closeTime={closeTime}></MessageBox>,
        document.createElement("alert_box_div")
    );
}
var Danger = function (body, heading, closeTime) {
    return ReactDOM.render(
        <MessageBox variant="Danger" body={body} heading={heading} closeTime={closeTime}></MessageBox>,
        document.createElement("alert_box_div")
    );
}
const Message = {
    Success,
    Default,
    Danger,
 };
 export  { Message };
