/**
 * get the size of a file with the correct unit
 * size < 1KB -> BYTES
 * size < 1MB -> KB
 * size < 1GB -> GB
 * size > 1GB -> GB
 * @param size
 * @returns {string}
 */
export const getSizeWithUnit = (size) => {
  const units = ['bytes', 'KB', 'MB', 'GB']
  let l = 0
  let n = parseInt(size, 10) || 0

  while (n >= 1024 && ++l) {
    n = n / 1024
  }
  let decimalCount = n && n.toString().split('.').length !== 0 ? n.toString().split('.').length : 0
  let roundedSize = decimalCount < 2 ? n : n.toFixed(1)
  return (`${roundedSize} ${units[l]}`)
}

/**
 * set the focus to an element with the given id
 * @param elementId
 */
export const setFocusToElement = (elementId) => {
  const el = document.getElementById(elementId)
  if (el) { el.focus() }
}

/**
 * get the data url of an image
 * @param url
 * @param id
 */
export const compareFileListArrays = (prevArr, newArr) => {
  if (!prevArr || !newArr) return false
  let result = false

  if (prevArr.length !== newArr.length) {
    result = true
  } else {
    for (let indexPrevArr = 0; indexPrevArr <= prevArr.length - 1; indexPrevArr++) {
      if ((prevArr[indexPrevArr].uploadProgress !== newArr[indexPrevArr].uploadProgress) ||
        (prevArr[indexPrevArr].status !== newArr[indexPrevArr].status)) {
        result = true
        break
      }
    }
  }
  return result
}
