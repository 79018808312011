import React, { FunctionComponent, useState } from "react";
import styles from "./toggleButton.module.scss";

type Option = {
  value: string;
  title: string;
};
interface Props {
  options: Option[];
  onChange: (value: string) => void;
}

const ToggleButton: FunctionComponent<Props> = (props) => {
  const [selected, setSelected] = useState(props.options[0].value);
  return (
    <div className={styles.toggle_button} role="group">
      {props.options.map((option, index) => (
        <button
          key={index}
          className={`${styles.toggle_button_item} ${
            selected === option.value
              ? styles.toggle_button_item_selected
              : null
          }`}
          value={option.value}
          aria-pressed={selected === option.value}
          onClick={() => {
            setSelected(option.value);
            props.onChange(option.value);
          }}
        >
          {option.title}
        </button>
      ))}
    </div>
  );
};

ToggleButton.defaultProps = {
  options: [],
};

export default ToggleButton;
