import React, { FunctionComponent } from "react";
import styles from "./table.module.scss";
import { FontWeightProperty, TextAlignProperty } from "csstype";

type Column = {
  title: string;
  dataIndex: string;
  key: number;
  fontWeight?: FontWeightProperty;
  titleAlign?: TextAlignProperty;
};

type Props = {
  columns: Column[];
};

const CollapsibleRowTableHeader: FunctionComponent<Props> = (props) => {
  return (
    <thead>
      <tr className={styles.table_header}>
        {props.columns.map((column, index) => {
          return (
            <th
              className={`${styles.table_header_cell}`}
              key={column.key}
              style={{
                fontWeight: column.fontWeight,
                textAlign: column.titleAlign,
              }}
            >
              {column.title}
            </th>
          );
        })}
        <th className={`${styles.table_header_cell}`} key={"chevron_th"}></th>
      </tr>
    </thead>
  );
};
export default CollapsibleRowTableHeader;
