/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2018 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import React from "react";
import PropType from "prop-types";
import MaximumPagination from "./MaximumPagination";
import CompactPaginationByPage from "./CompactPaginationByPage";
import PaginationGroup from "./PaginationGroup";
import PaginationFastForward from "./PaginationFastForward";

const Pagination = ({
  totalPages,
  dataPerPage,
  numberOfRecords,
  type,
  initialPageCount,
  currentPageNum,
  handlePage,
  rowsPerGroup,
  consumer,
  className,
  backAriaLabel,
  nextAriaLabel
}) => {
  switch (type) {
    case "MAXIMUM":
      return (
        <MaximumPagination
          type="MAXIMUM"
          currentPageNum={currentPageNum}
          totalPages={totalPages}
          dataPerPage={dataPerPage}
          handlePage={handlePage}
          numberOfRecords={numberOfRecords}
          initialPageCount={initialPageCount}
          backAriaLabel={backAriaLabel}
          nextAriaLabel={nextAriaLabel}
        />
      );
    case "FASTFORWARD":
      return (
        <PaginationFastForward
          type="FASTFORWARD"
          currentPageNum={currentPageNum}
          totalPages={totalPages}
          handlePage={handlePage}
          initialPageCount={initialPageCount}
          backAriaLabel={backAriaLabel}
          nextAriaLabel={nextAriaLabel}
        />
      );
    case "COMPACTPAGE":
      return (
        <CompactPaginationByPage
          type="COMPACTPAGE"
          currentPageNum={currentPageNum}
          totalPages={totalPages}
          handlePage={handlePage}
          consumer={consumer}
          className={className}
          backAriaLabel={backAriaLabel}
          nextAriaLabel={nextAriaLabel}
        />
      );
    case "COMPACTGROUP":
      return (
        <PaginationGroup
          rowsPerGroup={rowsPerGroup}
          numberOfRecords={numberOfRecords}
          handlePage={handlePage}
          currentPageNum={currentPageNum}
          totalPages={totalPages}
          backAriaLabel={backAriaLabel}
          nextAriaLabel={nextAriaLabel}
        />
      );
    default:
      break;
  }
};

Pagination.propTypes = {
  currentPageNum: PropType.number,
  totalPages: PropType.number,
  dataPerPage: PropType.number,
  numberOfRecords: PropType.number,
  handlePage: PropType.func,
  pageLabel: PropType.string,
  initialPageCount: PropType.number,
  type: PropType.string,
  rowsPerPage: PropType.number,
  consumer: PropType.string,
  className: PropType.string,
  backAriaLabel: PropType.string,
  nextAriaLabel: PropType.string
};
Pagination.defaultProps = {
  handlePage: () => {/**/},
  type: "MAXIMUM",
  backAriaLabel: "Previous page",
  nextAriaLabel: "Next page"
};

export default Pagination;
