import React from "react";
import "./loadingindicator.scss";
import PropTypes from "prop-types";

const LoadingIndicator = ({
  circleX,
  circleY,
  radius,
  strokeWidth,
  viewBox,
  color,
  width,
  height,
  dashDuration,
  rotateDuration,
  ariaLabel,
  isHidden,
  labelId,
  isLoading,
}) => {
  return (
    <div>
      <svg
        className="lf-spinner"
        aria-hidden={isHidden}
        aria-label={ariaLabel}
        id={labelId}
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuetext="Loading"
        role="progressbar"
        alt="loading spinner"
        viewBox={viewBox}
        style={{
          width: width,
          height: height,
          animationDuration: rotateDuration,
        }}
      >
        <circle
          className="lf-path"
          cx={circleX}
          cy={circleY}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          style={{ stroke: color, animationDuration: dashDuration }}
        />
      </svg>
    </div>
  );
};
LoadingIndicator.propTypes = {
  circleX: PropTypes.string,
  circleY: PropTypes.string,
  radius: PropTypes.string,
  strokeWidth: PropTypes.string,
  viewBox: PropTypes.string,
  color: PropTypes.string,
  labelId: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  dashDuration: PropTypes.string,
  rotateDuration: PropTypes.string,
  ariaLabel: PropTypes.string,
  isHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  circleX: "25",
  circleY: "25",
  radius: "20",
  strokeWidth: "5",
  viewBox: "0 0 50 50",
  color: "#047a9c",
  width: "50px",
  height: "50px",
  dashDuration: "2s",
  rotateDuration: "2s",
  ariaLabel: "loading",
  isHidden: false,
  isLoading: false,
  labelId: "lf-loading-indicator",
};

export default LoadingIndicator;
