import React, { FunctionComponent } from "react";
import Container from "./../Common/Container.tsx";
import styles from "./card.module.scss";

enum layouts {
  row = "row",
  column = "column",
}

enum aligns {
  start_start = "start_start",
  start_center = "start_center",
  start_end = "start_end",
  end_start = "end_start",
  end_end = "end_end",
  end_center = "end_center",
  center_start = "center_start",
  center_end = "center_end",
  center_center = "center_center",
  space_between_start = "space_between_start",
  space_between_center = "space_between_center",
  space_between_end = "space_between_end",
  space_around_start = "space_around_start",
  space_around_end = "space_around_end",
  space_around_center = "space_around_center",
}

interface Props {
  layout?: layouts;
  align?: aligns;
  columnstart?: number;
  columnend?: number;
  className?: string;
  rowstart?: number;
  rowend?:number;
}

const CardContent: FunctionComponent<Props> = (props) => {
  return (
    <Container
      className={`${styles.card_content} ${props.className}`}
      align={props.align}
      layout={props.layout}
      columnstart={props.columnstart}
      columnend={props.columnend}
      rowstart={props.rowstart}
      rowend={props.rowend}
    >
      {props.children}
    </Container>
  );
};

CardContent.defaultProps = {
  layout: layouts.column,
  align: aligns.start_start,
};

export default CardContent;
