import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SingleFile from './SingleFile'
import UploadingProgressLabel from './UploadingProgressLabel'
import styles from '../file-upload.module.scss'
import UploaderContext from '../containers/UploaderContext'

export const FileUploadingView = ({ deleteFilesCallback, hideRemoveButton }) => {
  const context = useContext(UploaderContext)
  const handleDeleteFile = (index) => {
    deleteFilesCallback(index)
  }
  const fileList = context.state.files.map((file, index) => {
    return <SingleFile key={`${file.fileId}-${index}`} file={file} deleteFile={handleDeleteFile} index={index}
      setErrorMessage={context.setErrorMessage} id={context.state.id}
      hideRemoveButton= {hideRemoveButton} />
  })
  return (
    <div className={styles.rectangleWithoutModal} id={`fileList-${context.state.id}`}>
      <UploadingProgressLabel />
      {fileList}
    </div>
  )
}

FileUploadingView.propTypes = {
  deleteFilesCallback: PropTypes.func
}

export default FileUploadingView
