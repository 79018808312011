import React from 'react'
import PropTypes from 'prop-types'
import ProgressCircle from '../../../ProgressCircle/ProgressCircle'
import Icon from '../../../Icon'
import uploadStyles from '../file-upload.module.scss'
import { PROCESSING } from '../const/uploader'

export const FileUploadProgressCircle = ({ file, styles, isCircleHidden, isCheckMarkVisible }) => {
  let htmlContent = ''
  let file_size = file.uploadProgress * 100;
  if(file.fileSize){
    file_size = Math.round((file.uploadProgress / file.fileSize) * 100)
  }
  htmlContent = isCircleHidden ? <span className={uploadStyles.completedIcon}>
    <Icon fileName='correct-18' name='upload completed' /></span> : <ProgressCircle
      getProgressMessage={(value) => {
        return `${file.fileName} : ${value}% uploaded`
      }}
      {...(styles)}
      isCheckMarkVisible={isCheckMarkVisible}
      value={file_size}
      isLoader={file.status === PROCESSING}
  />

  return (
    htmlContent
  )
}

FileUploadProgressCircle.propTypes = {
  isCircleHidden: PropTypes.bool.isRequired,
  file: PropTypes.object,
  styles: PropTypes.object,
  isCheckMarkVisible: PropTypes.bool
}

export default FileUploadProgressCircle
