/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component } from "react";
import styles from "./card.module.scss";

enum cardTypes {
  stack = "stack",
  modular = "modular",
}

enum columnVariations {
  three = 3,
  four = 4,
  ten = 10,
  twelve = 12,
}

interface Props {
  cardType: cardTypes;
  columns: columnVariations;
  id: string;
}

class Card extends Component<Props & React.HTMLAttributes<HTMLDivElement>, unknown> {
  computeClassName = () => {
    switch (this.props.cardType) {
      case cardTypes.modular:
        switch (this.props.columns) {
          case columnVariations.three:
            return `${styles.card} ${styles.card_modular} ${styles.card_span_3} ${this.props.className}`;
            
          case columnVariations.four:
            return `${styles.card} ${styles.card_modular} ${styles.card_span_4} ${this.props.className}`;
            
          default:
            return `${styles.card} ${styles.card_modular} ${styles.card_span_3} ${this.props.className}`;
            
        }
       
      case cardTypes.stack:
        switch (this.props.columns) {
          case columnVariations.ten:
            return `${styles.card} ${styles.card_stack} ${styles.card_span_10} ${this.props.className}`;
            
          case columnVariations.twelve:
            return `${styles.card} ${styles.card_stack} ${styles.card_span_12} ${this.props.className}`;
           
          default:
            return `${styles.card} ${styles.card_stack} ${styles.card_span_10} ${this.props.className}`;
            
        }
        
    }
  };

  render() {
    return (
      <div id={this.props.id} className={this.computeClassName()}>
        {this.props.children}
      </div>
    );
  }

  static defaultProps = {
    cardType: cardTypes.stack,
    columns: columnVariations.three,
  };
}

export default Card;
