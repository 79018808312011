import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Button from "../Button";

class ShortAnswerSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      response: this.props.initialState ? this.props.initialState : ''
    }
  }

  onChange = (e) => {
    this.setState({
      response: e.target.value
    }, () => {
      if (this.props.onChange) this.props.onChange(this.state.response)
    })
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.response)
  }

  render () {
    const { questionId } = this.props
    const { response } = this.state

    return (
      <div className='answer-section'>
        <div className='answer-field' id={`${questionId}-rg`}>
          <label className='pe-textLabelInput__label sr-only' htmlFor={`textarea-${questionId}`}>Answer field</label>
          <textarea
            className='pe-multiLineText'
            rows='5'
            id={`textarea-${questionId}`}
            value={response}
            onChange={this.onChange}
          />
        </div>
        <Button
          id={`${questionId}-submit-button`}
          buttonSize='large'
          buttonType='default'
          isDisabled={false}
          onClick={this.onSubmit}
        >Submit</Button>
      </div>
    )
  }
}

ShortAnswerSection.propTypes = {
  questionId: PropTypes.string.isRequired,
  initialState: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
}

export default ShortAnswerSection
