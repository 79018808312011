import axios from "axios";

export const _post = (url, headers, data, successCallback, errorCallback) => {
  return axios({
    method: "POST",
    url,
    headers,
    data,
  })
    .then(function (response) {
      // handle success
      successCallback(response);
    })
    .catch(function (error) {
      // handle error
      errorCallback(error);
    });
};

export const _get = (url, headers, successCallback, errorCallback) => {
  return axios({
    method: "get",
    url,
    headers,
  })
    .then(function (response) {
      // handle success
      successCallback(response);
    })
    .catch(function (error) {
      // handle error
      errorCallback(error);
    });
};

export const _put = (url, headers, data, successCallback, errorCallback) => {
  return axios({
    method: "put",
    url,
    headers,
    data,
  })
    .then(function (response) {
      // handle success
      successCallback(response);
    })
    .catch(function (error) {
      // handle error
      errorCallback(error);
    });
};
