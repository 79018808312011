import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../Icon'
import { MINIMIZE, OPEN } from '../const/uploader'
import styles from '../file-upload.module.scss'

export const UploadTitleBar = ({ uploadLabel, setUploader, handleClose, setUploadMinimizerView,
                                 minimizeIconEnabled, id }) => {
  const handleMinimize = () => {
    setUploader(MINIMIZE)
    setUploadMinimizerView(OPEN)
  }
  return (
    <div className={styles.titleBarInitial}>
      <h2 className={styles.uploadFiles}>{uploadLabel}</h2>
      <div className={styles.barIconsInitial}>
        { minimizeIconEnabled && <div className={styles.trimMinimize}>
          <button
            id={`MinimizeButton-${id}`}
            className={styles.iconBtn}
            aria-label='minimize the file uploader'
            onClick={() => handleMinimize()}
          >
            <Icon fileName='minimize-24' name={'minimize the file uploader'} title='minimize the file uploader' />
          </button>
        </div> }
        <div className={styles.trimClose}>
          <button
            id='CloseButton'
            className={styles.iconBtn}
            aria-label='close the file uploader'
            onClick={(e) => handleClose(e)}
          >
            <Icon fileName='close-24' name={'close the file uploader'} title='close the file uploader' />
          </button>
        </div>
      </div>
    </div>
  )
}

UploadTitleBar.propTypes = {
  uploadLabel: PropTypes.string.isRequired,
  setUploader : PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleClose : PropTypes.func.isRequired,
  setUploadMinimizerView: PropTypes.func.isRequired,
  minimizeIconEnabled: PropTypes.bool.isRequired
}

export default UploadTitleBar
